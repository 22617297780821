import { Observable } from 'rxjs';

export interface CountryPictures {
  title: string;
  value: string;
}
export interface CountryInformationSummary {
  title: string;
  value: [ {jour: string, visite:string}];
}

export abstract class CountryInformationData {
  abstract getInformationJson(lienInfo: string): Observable<any>;

}
