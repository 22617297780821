import { Information } from './../data/country';
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CountryData, AfricanCountry, AllCountry, AsianCountry, AmericanCountry, NorthAmericanCountry, SouthAmericanCountry, CentraleAmericanCountry, CaraibeanCountry, EuropeanCountry, MoyenOrientCountry, OceanAtlantiqueCountry, OceanIndienCountry, OceanPacifiqueCountry } from '../data/country';

@Injectable()
export class CountryService extends CountryData {

  private time: Date = new Date;

  private types = {
    chill: 'chill',
    galere: 'galere',
    mixte: 'mixte',
  };

  private regionsTypes = {
    afrique: 'Afrique',
    amerique: 'Amerique',
    asie: 'Asie',
    caraibes: 'Caraibes',
    europe: 'Europe',
    moyentOrient: 'Moyent-Orient',
    oceanAtlantique: 'Océan Atlantique',
    oceanIndien: 'Océan Indien',
    oceanPacifique: 'Océan Pacifique',
  };

  private countries = {
    // AFRIQUE
    afriqueDuSud: {
      type: 'afrique-du-sud', name: 'Afrique du Sud', region: this.regionsTypes.afrique, flag: 'assets/drapeau/afrique-du-sud.svg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [6, 6, 6, 6, 6, 6],
    },
    algerie: {
      type: 'algerie', name: 'Algérie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/algerie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [6, 6, 6, 10, 10, 10],
    },
    angola: {
      type: 'angola', name: 'Angola', region: this.regionsTypes.afrique, flag: 'assets/drapeau/angola.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    benin: {
      type: 'benin', name: 'Bénin', region: this.regionsTypes.afrique, flag: 'assets/drapeau/benin.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    botswana: {
      type: 'botswana', name: 'Botswana', region: this.regionsTypes.afrique, flag: 'assets/drapeau/botswana.svg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    burkinaFaso: {
      type: 'burkina-faso', name: 'Burkina Faso', region: this.regionsTypes.afrique, flag: 'assets/drapeau/burkina-faso.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    burundi: {
      type: 'burundi', name: 'Burundi', region: this.regionsTypes.afrique, flag: 'assets/drapeau/burundi.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    cabinda: {
      type: 'cabinda', name: 'Cabinda', region: this.regionsTypes.afrique, flag: 'assets/drapeau/cabinda.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    cameroun: {
      type: 'cameroun', name: 'Cameroun', region: this.regionsTypes.afrique, flag: 'assets/drapeau/cameroun.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tchad: {
      type: 'tchad', name: 'Tchad', region: this.regionsTypes.afrique, flag: 'assets/drapeau/tchad.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    coteDIvoire: {
      type: 'cote-d-ivoire', name: 'Cote d\'Ivoire', region: this.regionsTypes.afrique, flag: 'assets/drapeau/cote-d-ivoire.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    djibouti: {
      type: 'djibouti', name: 'Djibouti', region: this.regionsTypes.afrique, flag: 'assets/drapeau/djibouti.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    egypte: {
      type: 'egypte', name: 'Egypte', region: this.regionsTypes.afrique, flag: 'assets/drapeau/egypte.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    erythree: {
      type: 'erythree', name: 'Erythrée', region: this.regionsTypes.afrique, flag: 'assets/drapeau/erythree.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ethiopie: {
      type: 'ethiopie', name: 'Ethiopie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/ethiopie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    gabon: {
      type: 'gabon', name: 'Gabon', region: this.regionsTypes.afrique, flag: 'assets/drapeau/gabon.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    gambie: {
      type: 'gambie', name: 'Gambie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/gambie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ghana: {
      type: 'ghana', name: 'Ghana', region: this.regionsTypes.afrique, flag: 'assets/drapeau/ghana.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guineeBissau: {
      type: 'guinee-bissau', name: 'Guinée Bissau', region: this.regionsTypes.afrique, flag: 'assets/drapeau/guinee-bissau.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guineeEquatoriale: {
      type: 'guinee-equatoriale', name: 'Guinée Equatoriale', region: this.regionsTypes.afrique, flag: 'assets/drapeau/guinee-equatoriale.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guineeFrancaise: {
      type: 'guinee-francaise', name: 'Guinée Francaise', region: this.regionsTypes.afrique, flag: 'assets/drapeau/guinee-francaise.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    kenya: {
      type: 'kenya', name: 'Kenya', region: this.regionsTypes.afrique, flag: 'assets/drapeau/kenya.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    lesotho: {
      type: 'lesotho', name: 'Lesotho', region: this.regionsTypes.afrique, flag: 'assets/drapeau/lesotho.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    liberie: {
      type: 'liberie', name: 'Liberie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/liberie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    libye: {
      type: 'libye', name: 'Libye', region: this.regionsTypes.afrique, flag: 'assets/drapeau/libye.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    malawi: {
      type: 'malawi', name: 'Malawi', region: this.regionsTypes.afrique, flag: 'assets/drapeau/malawi.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mali: {
      type: 'mali', name: 'Mali', region: this.regionsTypes.afrique, flag: 'assets/drapeau/mali.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    maroc: {
      type: 'maroc', name: 'Maroc', region: this.regionsTypes.afrique, flag: 'assets/drapeau/maroc.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mauritanie: {
      type: 'mauritanie', name: 'Mauritanie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/mauritanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mozambique: {
      type: 'mozambique', name: 'Mozambique', region: this.regionsTypes.afrique, flag: 'assets/drapeau/mozambique.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    namibie: {
      type: 'namibie', name: 'Namibie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/namibie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    niger: {
      type: 'niger', name: 'Niger', region: this.regionsTypes.afrique, flag: 'assets/drapeau/niger.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nigeria: {
      type: 'nigeria', name: 'Nigeria', region: this.regionsTypes.afrique, flag: 'assets/drapeau/nigeria.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ouganda: {
      type: 'ouganda', name: 'Ouganda', region: this.regionsTypes.afrique, flag: 'assets/drapeau/ouganda.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    republiqueCentrafricaine: {
      type: 'republique-centrafricaine', name: 'République Centrafricaine', region: this.regionsTypes.afrique, flag: 'assets/drapeau/republique-centrafricaine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    republiqueDemocratiqueDuCongo: {
      type: 'republique-democratique-du-congo', name: 'République Démocratique du Congo', region: this.regionsTypes.afrique, flag: 'assets/drapeau/republique-democratique-du-congo.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    republiqueDuCongo: {
      type: 'republique-du-congo', name: 'République du Congo', region: this.regionsTypes.afrique, flag: 'assets/drapeau/republique-du-congo.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    rwanda: {
      type: 'rwanda', name: 'Rwanda', region: this.regionsTypes.afrique, flag: 'assets/drapeau/rwanda.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    saharaOccidental: {
      type: 'sahara-occidental', name: 'Sahara Occidental', region: this.regionsTypes.afrique, flag: 'assets/drapeau/sahara-occidental.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    saoTomeEtPrincipe: {
      type: 'sao-tome-et-principe', name: 'Sao Tome et Principe', region: this.regionsTypes.afrique, flag: 'assets/drapeau/sao-tome-et-principe.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    senegal: {
      type: 'senegal', name: 'Sénégal', region: this.regionsTypes.afrique, flag: 'assets/drapeau/senegal.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    sierraLeone: {
      type: 'sierra-leone', name: 'Sierra Leone', region: this.regionsTypes.afrique, flag: 'assets/drapeau/sierra-leone.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    somalie: {
      type: 'somalie', name: 'Somalie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/somalie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    somaliland: {
      type: 'somaliland', name: 'Somaliland', region: this.regionsTypes.afrique, flag: 'assets/drapeau/somaliland.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    soudan: {
      type: 'soudan', name: 'Soudan', region: this.regionsTypes.afrique, flag: 'assets/drapeau/soudan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    soudanDuSud: {
      type: 'soudan-du-sud', name: 'Soudan du Sud', region: this.regionsTypes.afrique, flag: 'assets/drapeau/soudan-du-sud.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    swaziland: {
      type: 'swaziland', name: 'Swaziland', region: this.regionsTypes.afrique, flag: 'assets/drapeau/swaziland.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tanzanie: {
      type: 'tanzanie', name: 'Tanzanie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/tanzanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    togo: {
      type: 'togo', name: 'Togo', region: this.regionsTypes.afrique, flag: 'assets/drapeau/togo.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tunisie: {
      type: 'tunisie', name: 'Tunisie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/tunisie.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    zambie: {
      type: 'zambie', name: 'Zambie', region: this.regionsTypes.afrique, flag: 'assets/drapeau/zambie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    zimbabwe: {
      type: 'zimbabwe', name: 'Zimbabwe', region: this.regionsTypes.afrique, flag: 'assets/drapeau/zimbabwe.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // AMERIQUE
    // AMERIQUE DU NORD
    alaska: {
      type: 'alaska', name: 'Alaska', region: this.regionsTypes.amerique, flag: 'assets/drapeau/alaska.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    canada: {
      type: 'canada', name: 'Canada', region: this.regionsTypes.amerique, flag: 'assets/drapeau/canada.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    etatsUnis: {
      type: 'etats-unis', name: 'Etats Unis', region: this.regionsTypes.amerique, flag: 'assets/drapeau/etats-unis.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: 'Non < 70j' }, { title: 'Durée de Vol min', value: 'erfrf' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [15, 15, 15, 15, 15, 15],
    },
    ilePrinceEdward: {
      type: 'ile-prince-edward', name: 'Ile Prince Edward', region: this.regionsTypes.amerique, flag: 'assets/drapeau/ile-prince-edward.jpg',
      informations: [{ title: 'Budget', value: 'kyuky' }, { title: 'Visa ?', value: 'kyuuky' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mexique: {
      type: 'mexique', name: 'Mexique', region: this.regionsTypes.amerique, flag: 'assets/drapeau/mexique.png',
      informations: [{ title: 'Budget', value: 'ykuk' }, { title: 'Visa ?', value: 'kyuk' }, { title: 'Durée de Vol min', value: 'ollul' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [9, 9, 9, 9, 10, 10],
    },
    stPierreEtMiquelon: {
      type: 'st-pierre-et-miquelon', name: 'St Pierre et Miquelon', region: this.regionsTypes.amerique, flag: 'assets/drapeau/st-pierre-et-miquelon.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // AMERIQUE DU SUD
    argentine: {
      type: 'argentine', name: 'Argentine', region: this.regionsTypes.amerique, flag: 'assets/drapeau/argentine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bolivie: {
      type: 'bolivie', name: 'Bolivie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bolivie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bresil: {
      type: 'bresil', name: 'Bresil', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bresil.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    chili: {
      type: 'chili', name: 'Chili', region: this.regionsTypes.amerique, flag: 'assets/drapeau/chili.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    colombie: {
      type: 'colombie', name: 'Colombie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/colombie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    equateur: {
      type: 'equateur', name: 'Equateur', region: this.regionsTypes.amerique, flag: 'assets/drapeau/equateur.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guyane: {
      type: 'guyane', name: 'Guyane', region: this.regionsTypes.amerique, flag: 'assets/drapeau/guyane.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guyaneFrancaise: {
      type: 'guyane-francaise', name: 'Guyane Française', region: this.regionsTypes.amerique, flag: 'assets/drapeau/guyane-francaise.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    paraguay: {
      type: 'paraguay', name: 'Paraguay', region: this.regionsTypes.amerique, flag: 'assets/drapeau/paraguay.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    peru: {
      type: 'peru', name: 'Peru', region: this.regionsTypes.amerique, flag: 'assets/drapeau/peru.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    suriname: {
      type: 'suriname', name: 'Suriname', region: this.regionsTypes.amerique, flag: 'assets/drapeau/suriname.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    uruguay: {
      type: 'uruguay', name: 'Uruguay', region: this.regionsTypes.amerique, flag: 'assets/drapeau/uruguay.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    venezuela: {
      type: 'venezuela', name: 'Venezuela', region: this.regionsTypes.amerique, flag: 'assets/drapeau/venezuela.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // AMERIQUE CENTRALE
    belize: {
      type: 'belize', name: 'Belize', region: this.regionsTypes.amerique, flag: 'assets/drapeau/belize.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    costaRica: {
      type: 'costa-rica', name: 'Costa Rica', region: this.regionsTypes.amerique, flag: 'assets/drapeau/costa-rica.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    elSalvador: {
      type: 'el-salvador', name: 'El Salvador', region: this.regionsTypes.amerique, flag: 'assets/drapeau/el-salvador.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guatemala: {
      type: 'guatemala', name: 'Guatemala', region: this.regionsTypes.amerique, flag: 'assets/drapeau/guatemala.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    honduras: {
      type: 'honduras', name: 'Honduras', region: this.regionsTypes.amerique, flag: 'assets/drapeau/honduras.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nicaragua: {
      type: 'nicaragua', name: 'Nicaragua', region: this.regionsTypes.amerique, flag: 'assets/drapeau/nicaragua.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    panama: {
      type: 'panama', name: 'Panama', region: this.regionsTypes.amerique, flag: 'assets/drapeau/panama.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // ASIE
    abkhazia: {
      type: 'abkhazia', name: 'Abkhazia', region: this.regionsTypes.amerique, flag: 'assets/drapeau/abkhazia.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    afghanistan: {
      type: 'afghanistan', name: 'Afghanistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/afghanistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    armenie: {
      type: 'armenie', name: 'Armenie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/armenie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    azerbaidjan: {
      type: 'azerbaidjan', name: 'Azerbaïdjan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/azerbaidjan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bangladesh: {
      type: 'bangladesh', name: 'Bangladesh', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bangladesh.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bhoutan: {
      type: 'bhoutan', name: 'Bhoutan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bhoutan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    brunei: {
      type: 'brunei', name: 'Brunei', region: this.regionsTypes.amerique, flag: 'assets/drapeau/brunei.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    cambodge: {
      type: 'cambodge', name: 'Cambodge', region: this.regionsTypes.amerique, flag: 'assets/drapeau/cambodge.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    chine: {
      type: 'chine', name: 'Chine', region: this.regionsTypes.amerique, flag: 'assets/drapeau/chine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    coreeDuNord: {
      type: 'coree-du-nord', name: 'Corée du Nord', region: this.regionsTypes.amerique, flag: 'assets/drapeau/coree-du-nord.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    coreeDuSud: {
      type: 'coree-du-sud', name: 'Corée du sud', region: this.regionsTypes.amerique, flag: 'assets/drapeau/coree-du-sud.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    georgie: {
      type: 'georgie', name: 'Géorgie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/georgie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    inde: {
      type: 'inde', name: 'Inde', region: this.regionsTypes.amerique, flag: 'assets/drapeau/inde.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    indonesie: {
      type: 'indonesie', name: 'Indonésie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/indonesie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    japon: {
      type: 'japon', name: 'Japon', region: this.regionsTypes.amerique, flag: 'assets/drapeau/japon.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    kazakhstan: {
      type: 'kazakhstan', name: 'Kazakhstan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/kazakhstan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    kirghizistan: {
      type: 'kirghizistan', name: 'Kirghizistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/kirghizistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    laos: {
      type: 'laos', name: 'Laos', region: this.regionsTypes.amerique, flag: 'assets/drapeau/laos.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    malaisie: {
      type: 'malaisie', name: 'Malaisie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/malaisie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mongolie: {
      type: 'mongolie', name: 'Mongolie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/mongolie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    myanmar: {
      type: 'myanmar', name: 'Myanmar', region: this.regionsTypes.amerique, flag: 'assets/drapeau/myanmar.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nakhchivan: {
      type: 'nakhchivan', name: 'Nakhchivan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/nakhchivan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nepal: {
      type: 'nepal', name: 'Népal', region: this.regionsTypes.amerique, flag: 'assets/drapeau/nepal.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ouzbekistan: {
      type: 'ouzbekistan', name: 'Ouzbékistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/ouzbekistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    pakistan: {
      type: 'pakistan', name: 'Pakistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/pakistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    papouasie: {
      type: 'papouasie', name: 'Papouasie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/papouasie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    philippines: {
      type: 'philippines', name: 'Philippines', region: this.regionsTypes.asie, flag: 'assets/drapeau/philippines.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    siberie: {
      type: 'siberie', name: 'Sibérie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/siberie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    singapour: {
      type: 'singapour', name: 'Singapour', region: this.regionsTypes.amerique, flag: 'assets/drapeau/singapour.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    sriLanka: {
      type: 'sri-lanka', name: 'Sri Lanka', region: this.regionsTypes.amerique, flag: 'assets/drapeau/sri-lanka.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tadjikistan: {
      type: 'tadjikistan', name: 'Tadjikistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/tadjikistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    taiwan: {
      type: 'taiwan', name: 'Taiwan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/taiwan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    thailande: {
      type: 'thailande', name: 'Thailande', region: this.regionsTypes.asie, flag: 'assets/drapeau/thailande.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tibet: {
      type: 'tibet', name: 'Tibet', region: this.regionsTypes.amerique, flag: 'assets/drapeau/tibet.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    timorLeste: {
      type: 'timor-leste', name: 'Timor Leste', region: this.regionsTypes.amerique, flag: 'assets/drapeau/timor-leste.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    turkmenistan: {
      type: 'turkmenistan', name: 'Turkmenistan', region: this.regionsTypes.amerique, flag: 'assets/drapeau/turkmenistan.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    vietnam: {
      type: 'vietnam', name: 'Vietnam', region: this.regionsTypes.amerique, flag: 'assets/drapeau/vietnam.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // CARAIBES
    anguilla: {
      type: 'anguilla', name: 'Anguilla', region: this.regionsTypes.amerique, flag: 'assets/drapeau/anguilla.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    antigua: {
      type: 'antigua', name: 'Antigua', region: this.regionsTypes.amerique, flag: 'assets/drapeau/antigua.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    aruba: {
      type: 'aruba', name: 'Aruba', region: this.regionsTypes.amerique, flag: 'assets/drapeau/aruba.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bahamas: {
      type: 'bahamas', name: 'Bahamas', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bahamas.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    barbade: {
      type: 'barbade', name: 'Barbade', region: this.regionsTypes.amerique, flag: 'assets/drapeau/barbade.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bonaire: {
      type: 'bonaire', name: 'Bonaire', region: this.regionsTypes.amerique, flag: 'assets/drapeau/bonaire.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    cuba: {
      type: 'cuba', name: 'Cuba', region: this.regionsTypes.amerique, flag: 'assets/drapeau/cuba.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    curacao: {
      type: 'curacao', name: 'Curacao', region: this.regionsTypes.amerique, flag: 'assets/drapeau/curacao.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    dominique: {
      type: 'dominique', name: 'Dominique', region: this.regionsTypes.amerique, flag: 'assets/drapeau/dominique.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    grenade: {
      type: 'grenade', name: 'Grenade', region: this.regionsTypes.amerique, flag: 'assets/drapeau/grenade.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guadeloupe: {
      type: 'guadeloupe', name: 'Guadeloupe', region: this.regionsTypes.amerique, flag: 'assets/drapeau/guadeloupe.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    haiti: {
      type: 'haiti', name: 'Haïti', region: this.regionsTypes.amerique, flag: 'assets/drapeau/haiti.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesCaimans: {
      type: 'iles-caimans', name: 'Iles Caïmans', region: this.regionsTypes.amerique, flag: 'assets/drapeau/iles-caimans.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesLeeward: {
      type: 'iles-leeward', name: 'Iles Leeward', region: this.regionsTypes.amerique, flag: 'assets/drapeau/iles-leeward.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesTurquesEtCaiques: {
      type: 'iles-turques-et-caiques', name: 'Iles Turques et Caïques', region: this.regionsTypes.amerique, flag: 'assets/drapeau/iles-turques-et-caiques.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileVierge: {
      type: 'ile-vierge', name: 'Ile Vierge', region: this.regionsTypes.amerique, flag: 'assets/drapeau/ile-vierge.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    jamaique: {
      type: 'jamaique', name: 'Jamaïque', region: this.regionsTypes.amerique, flag: 'assets/drapeau/jamaique.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    martinique: {
      type: 'martinique', name: 'Martinique', region: this.regionsTypes.amerique, flag: 'assets/drapeau/martinique.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    montserrat: {
      type: 'montserrat', name: 'Montserrat', region: this.regionsTypes.amerique, flag: 'assets/drapeau/montserrat.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    puertoRico: {
      type: 'puerto-rico', name: 'Puerto Rico', region: this.regionsTypes.amerique, flag: 'assets/drapeau/puerto-rico.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    republiqueDominicaine: {
      type: 'republique-dominicaine', name: 'République Dominicaine', region: this.regionsTypes.amerique, flag: 'assets/drapeau/republique-dominicaine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    sainteLucie: {
      type: 'saint-lucie', name: 'Sainte Lucie', region: this.regionsTypes.amerique, flag: 'assets/drapeau/saint-lucie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    sanAndresEtProvidencia: {
      type: 'san-andres-et-providencia', name: 'San Andres et Providencia', region: this.regionsTypes.amerique, flag: 'assets/drapeau/san-andres-et-providencia.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    stBarth: {
      type: 'st-barth', name: 'St Barth', region: this.regionsTypes.amerique, flag: 'assets/drapeau/st-barth.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    stChristopheEtNieves: {
      type: 'st-christophe-et-nieves', name: 'St Christophe et Niévès', region: this.regionsTypes.europe, flag: 'assets/drapeau/st-christophe-et-nieves.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    stMartin: {
      type: 'st-martin', name: 'St Martin', region: this.regionsTypes.europe, flag: 'assets/drapeau/st-martin.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    stVincentEtDeps: {
      type: 'st-vincent-et-deps', name: 'St Vincent et Deps', region: this.regionsTypes.europe, flag: 'assets/drapeau/st-vincent-et-deps.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    trinidadEtTobago: {
      type: 'trinidad-et-tobago', name: 'Trinidad et Tobago', region: this.regionsTypes.europe, flag: 'assets/drapeau/trinidad-et-tobago.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // EUROPE
    albanie: {
      type: 'albanie', name: 'Albanie', region: this.regionsTypes.europe, flag: 'assets/drapeau/albanie.png',
      informations: [{ title: 'Budget', value: '< 750 €' }, { title: 'Visa ?', value: 'non < 3 mois' }, { title: 'Durée de Vol min', value: '2 h 30' }, { title: 'Nombre de points à voir', value: '5' }, { title: 'Transport obligatoire', value: 'Vehicule terrestre' }, { title: 'Decalage horraire', value: 'Aucun' }, { title: 'Killomètre à parcourir', value: '200 km' }, { title: 'Durée minimum sur place', value: '6 jours' }, { title: 'Meilleur periode', value: 'Juin à Septembre' }],
      nbPointInterest: [0, 0, 3, 3, 0, 0],
    },
    allemagne: {
      type: 'allemagne', name: 'Allemagne', region: this.regionsTypes.europe, flag: 'assets/drapeau/allemagne.png',
      informations: [{ title: 'Budget', value: '< 2 400€ ou 4 000€ (ski)' }, { title: 'Visa ?', value: 'non < 3 mois' }, { title: 'Durée de Vol min', value: '1h' }, { title: 'Nombre de points à voir', value: '33' }, { title: 'Transport obligatoire', value: 'Terrestre' }, { title: 'Decalage horraire', value: 'Aucun' }, { title: 'Killomètre à parcourir', value: '< 2 500 km' }, { title: 'Durée minimum sur place', value: '19 jours' }, { title: 'Meilleur periode', value: 'Juin à Septembre' }],
      nbPointInterest: [0, 0, 0, 0, 0, 0],
    },
    andorre: {
      type: 'andorre', name: 'Andorre', region: this.regionsTypes.europe, flag: 'assets/drapeau/andorre.png',
      informations: [{ title: 'Budget', value: '1 000 €' }, { title: 'Visa ?', value: 'non < 3 mois' }, { title: 'Durée de Vol min', value: '1h' }, { title: 'Nombre de points à voir', value: '1 seul' }, { title: 'Transport obligatoire', value: 'Terrestre' }, { title: 'Decalage horraire', value: 'Aucun' }, { title: 'Killomètre à parcourir', value: '< 50 km' }, { title: 'Durée minimum sur place', value: '4 à 7 jours' }, { title: 'Meilleur periode', value: 'Decembre à Mars' }],
      nbPointInterest: [0, 2, 0, 0, 0, 0],
    },
    angleterre: {
      type: 'angleterre', name: 'Angleterre', region: this.regionsTypes.europe, flag: 'assets/drapeau/angleterre.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    autriche: {
      type: 'autriche', name: 'Autriche', region: this.regionsTypes.europe, flag: 'assets/drapeau/autriche.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    belgique: {
      type: 'belgique', name: 'Belgique', region: this.regionsTypes.europe, flag: 'assets/drapeau/belgique.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bielorussie: {
      type: 'bielorussie', name: 'Bielorussie', region: this.regionsTypes.europe, flag: 'assets/drapeau/bielorussie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bosnieHerzegovine: {
      type: 'bosnie-herzegovine', name: 'Bosnie Herzegovine', region: this.regionsTypes.europe, flag: 'assets/drapeau/bosnie-herzegovine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bulgarie: {
      type: 'bulgarie', name: 'Bulgarie', region: this.regionsTypes.europe, flag: 'assets/drapeau/bulgarie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    chypre: {
      type: 'chypre', name: 'Chypre', region: this.regionsTypes.europe, flag: 'assets/drapeau/chypre.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    crete: {
      type: 'crete', name: 'Crète', region: this.regionsTypes.europe, flag: 'assets/drapeau/crete.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    croatie: {
      type: 'croatie', name: 'Croatie', region: this.regionsTypes.europe, flag: 'assets/drapeau/croatie.png',
      informations: [{ title: 'Budget', value: '2 500€' }, { title: 'Visa ?', value: 'non < 3 mois' }, { title: 'Durée de Vol min', value: '2 heures' }, { title: 'Nombre de points à voir', value: '15' }, { title: 'Transport obligatoire', value: 'terrestre' }, { title: 'Decalage horraire', value: 'Non' }, { title: 'Killomètre à parcourir', value: '< 1 600 km' }, { title: 'Durée minimum sur place', value: '15 jours' }, { title: 'Meilleur periode', value: 'Juin à Septembre' }],
      nbPointInterest: [, , , , , ],
    },
    danemark: {
      type: 'danemark', name: 'Danemark', region: this.regionsTypes.europe, flag: 'assets/drapeau/danemark.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ecosse: {
      type: 'ecosse', name: 'Ecosse', region: this.regionsTypes.europe, flag: 'assets/drapeau/ecosse.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    espagne: {
      type: 'espagne', name: 'Espagne', region: this.regionsTypes.europe, flag: 'assets/drapeau/espagne.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    estonie: {
      type: 'estonie', name: 'Estonie', region: this.regionsTypes.europe, flag: 'assets/drapeau/estonie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    finlande: {
      type: 'finlande', name: 'Finlande', region: this.regionsTypes.europe, flag: 'assets/drapeau/finlande.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    france: {
      type: 'france', name: 'France', region: this.regionsTypes.europe, flag: 'assets/drapeau/france.png'
      ,
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: 'Non' }, { title: 'Durée de Vol min', value: '0' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: 'Voiture ou Train' }, { title: 'Decalage horraire', value: '0' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: 'juin à aout' }],
      nbPointInterest: [3, 3, 3, 3, 3, 3],
    },
    gibraltar: {
      type: 'gibraltar', name: 'Gibraltar', region: this.regionsTypes.europe, flag: 'assets/drapeau/gibraltar.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    grece: {
      type: 'grece', name: 'Grèce', region: this.regionsTypes.europe, flag: 'assets/drapeau/grece.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    hongrie: {
      type: 'hongrie', name: 'Hongrie', region: this.regionsTypes.europe, flag: 'assets/drapeau/hongrie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesAngloNormandes: {
      type: 'iles-anglo-normandes', name: 'Iles Anglo-Normandes', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-anglo-normandes.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileDuMan: {
      type: 'ile-du-man', name: 'Ile du Man', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-du-man.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    irlande: {
      type: 'irlande', name: 'Irlande', region: this.regionsTypes.europe, flag: 'assets/drapeau/irlande.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    italie: {
      type: 'italie', name: 'Italie', region: this.regionsTypes.europe, flag: 'assets/drapeau/italie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    kosovo: {
      type: 'kosovo', name: 'Kosovo', region: this.regionsTypes.europe, flag: 'assets/drapeau/kosovo.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    liechtenstein: {
      type: 'liechtenstein', name: 'Liechtenstein', region: this.regionsTypes.europe, flag: 'assets/drapeau/liechtenstein.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    lettonie: {
      type: 'lettonie', name: 'Lettonie', region: this.regionsTypes.europe, flag: 'assets/drapeau/lettonie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    lituanie: {
      type: 'lituanie', name: 'Lituanie', region: this.regionsTypes.europe, flag: 'assets/drapeau/lituanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    luxembourg: {
      type: 'luxembourg', name: 'Luxembourg', region: this.regionsTypes.europe, flag: 'assets/drapeau/luxembourg.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    macedoine: {
      type: 'macedoine', name: 'Macedoine', region: this.regionsTypes.europe, flag: 'assets/drapeau/macedoine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    malte: {
      type: 'malte', name: 'Malte', region: this.regionsTypes.europe, flag: 'assets/drapeau/malte.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    moldavie: {
      type: 'moldavie', name: 'Moldavie', region: this.regionsTypes.europe, flag: 'assets/drapeau/moldavie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    monaco: {
      type: 'monaco', name: 'Monaco', region: this.regionsTypes.europe, flag: 'assets/drapeau/monaco.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    montenegro: {
      type: 'montenegro', name: 'Monténégro', region: this.regionsTypes.europe, flag: 'assets/drapeau/montenegro.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    norvege: {
      type: 'norvege', name: 'Norvège', region: this.regionsTypes.europe, flag: 'assets/drapeau/norvege.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    paysBas: {
      type: 'pays-bas', name: 'Pays Bas', region: this.regionsTypes.europe, flag: 'assets/drapeau/pays-bas.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    paysDeGalles: {
      type: 'pays-de-galles', name: 'Pays de Galles', region: this.regionsTypes.europe, flag: 'assets/drapeau/pays-de-galles.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    pologne: {
      type: 'pologne', name: 'Pologne', region: this.regionsTypes.europe, flag: 'assets/drapeau/pologne.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    portugal: {
      type: 'portugal', name: 'Portugal', region: this.regionsTypes.europe, flag: 'assets/drapeau/portugal.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    republiqueTcheque: {
      type: 'republique-tcheque', name: 'République Tchèque', region: this.regionsTypes.europe, flag: 'assets/drapeau/republique-tcheque.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    roumanie: {
      type: 'roumanie', name: 'Roumanie', region: this.regionsTypes.europe, flag: 'assets/drapeau/roumanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    russie: {
      type: 'russie', name: 'Russie', region: this.regionsTypes.europe, flag: 'assets/drapeau/russie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    serbie: {
      type: 'serbie', name: 'Serbie', region: this.regionsTypes.europe, flag: 'assets/drapeau/serbie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    slovaquie: {
      type: 'slovaquie', name: 'Slovaquie', region: this.regionsTypes.europe, flag: 'assets/drapeau/slovaquie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    slovenie: {
      type: 'slovenie', name: 'Slovénie', region: this.regionsTypes.europe, flag: 'assets/drapeau/slovenie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    suede: {
      type: 'suede', name: 'Suède', region: this.regionsTypes.europe, flag: 'assets/drapeau/suede.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    suisse: {
      type: 'suisse', name: 'Suisse', region: this.regionsTypes.europe, flag: 'assets/drapeau/suisse.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    turquie: {
      type: 'turquie', name: 'Turquie', region: this.regionsTypes.europe, flag: 'assets/drapeau/turquie.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ukraine: {
      type: 'ukraine', name: 'Ukraine', region: this.regionsTypes.europe, flag: 'assets/drapeau/ukraine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // MOYEN-ORIENT
    abuDhabi: {
      type: 'abu-dhabi', name: 'Abu Dhabi', region: this.regionsTypes.europe, flag: 'assets/drapeau/abu-dhabi.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ajman: {
      type: 'ajman', name: 'Ajman', region: this.regionsTypes.europe, flag: 'assets/drapeau/ajman.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    arabieSaoudite: {
      type: 'arabie-saoudite', name: 'Arabie Saoudite', region: this.regionsTypes.europe, flag: 'assets/drapeau/arabie-saoudite.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    bahrein: {
      type: 'bahrein', name: 'Bahreïn', region: this.regionsTypes.europe, flag: 'assets/drapeau/bahrein.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    charjah: {
      type: 'charjah', name: 'Charjah', region: this.regionsTypes.europe, flag: 'assets/drapeau/charjah.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    dubai: {
      type: 'dubai', name: 'Dubaï', region: this.regionsTypes.europe, flag: 'assets/drapeau/dubai.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    fujairah: {
      type: 'fujairah', name: 'Fujairah', region: this.regionsTypes.europe, flag: 'assets/drapeau/fujairah.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    iran: {
      type: 'iran', name: 'Iran', region: this.regionsTypes.europe, flag: 'assets/drapeau/iran.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    irak: {
      type: 'irak', name: 'Irak', region: this.regionsTypes.europe, flag: 'assets/drapeau/irak.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    israel: {
      type: 'israel', name: 'Israël', region: this.regionsTypes.europe, flag: 'assets/drapeau/israel.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    jordanie: {
      type: 'jordanie', name: 'Jordanie', region: this.regionsTypes.europe, flag: 'assets/drapeau/jordanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    koweit: {
      type: 'koweit', name: 'Koweït', region: this.regionsTypes.europe, flag: 'assets/drapeau/koweit.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    liban: {
      type: 'liban', name: 'Liban', region: this.regionsTypes.europe, flag: 'assets/drapeau/liban.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    oman: {
      type: 'oman', name: 'Oman', region: this.regionsTypes.europe, flag: 'assets/drapeau/oman.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    oummAlQaiwain: {
      type: 'oumm-al-qaiwain', name: 'Oumm al Qaiwain', region: this.regionsTypes.europe, flag: 'assets/drapeau/oumm-al-qaiwain.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    palestine: {
      type: 'palestine', name: 'Palestine', region: this.regionsTypes.europe, flag: 'assets/drapeau/palestine.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    qatar: {
      type: 'qatar', name: 'Qatar', region: this.regionsTypes.europe, flag: 'assets/drapeau/qatar.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    rasAlKhaimah: {
      type: 'ras-al-khaimah', name: 'Ras al Khaimah', region: this.regionsTypes.europe, flag: 'assets/drapeau/ras-al-khaimah.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    syrie: {
      type: 'syrie', name: 'Syrie', region: this.regionsTypes.europe, flag: 'assets/drapeau/syrie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    yemen: {
      type: 'yemen', name: 'Yemen', region: this.regionsTypes.europe, flag: 'assets/drapeau/yemen.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // OCEAN ATLANTIQUE
    ascension: {
      type: 'ascension', name: 'Ascension', region: this.regionsTypes.europe, flag: 'assets/drapeau/ascension.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    capVert: {
      type: 'cap-vert', name: 'Cap Vert', region: this.regionsTypes.europe, flag: 'assets/drapeau/cap-vert.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    fernandoDoNoronha: {
      type: 'fernando-do-noronha', name: 'Fernando do Noronha', region: this.regionsTypes.europe, flag: 'assets/drapeau/fernando-do-noronha.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    groenland: {
      type: 'groenland', name: 'Groenland', region: this.regionsTypes.europe, flag: 'assets/drapeau/groenland.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesCanaries: {
      type: 'iles-canaries', name: 'Iles Canaries', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-canaries.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileDesBermudes: {
      type: 'ile-des-bermudes', name: 'Ile des Bermudes', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-des-bermudes.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileFeroe: {
      type: 'ile-feroe', name: 'Ile Féroé', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-feroe.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesAcores: {
      type: 'iles-acores', name: 'Iles Açores', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-acores.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesFalkland: {
      type: 'iles-falkland', name: 'Iles Falkland', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-falkland.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    islande: {
      type: 'islande', name: 'Islande', region: this.regionsTypes.europe, flag: 'assets/drapeau/islande.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    madeira: {
      type: 'madeira', name: 'Madeira', region: this.regionsTypes.europe, flag: 'assets/drapeau/madeira.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    stHelena: {
      type: 'st-helena', name: 'St Helena', region: this.regionsTypes.europe, flag: 'assets/drapeau/st-helena.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tristanDaCunha: {
      type: 'tristan-da-cunha', name: 'Tristan da Cunha', region: this.regionsTypes.europe, flag: 'assets/drapeau/tristan-da-cunha.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // OCEAN INDIEN
    andamanIlesNicobar: {
      type: 'andaman-iles-nicobar', name: 'Andaman iles Nicobar', region: this.regionsTypes.europe, flag: 'assets/drapeau/andaman-iles-nicobar.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileCocos: {
      type: 'ile-cocos', name: 'Ile Cocos', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-cocos.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileDeLaReunion: {
      type: 'ile-de-la-reunion', name: 'Ile de la Reunion', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-de-la-reunion.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileMaurice: {
      type: 'ile-maurice', name: 'Ile Maurice', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-maurice.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesChristmas: {
      type: 'iles-christmas', name: 'Iles Christmas', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-christmas.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesComores: {
      type: 'iles-comores', name: 'Iles Comores', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-comores.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesRodriguez: {
      type: 'iles-rodriguez', name: 'Iles Rodriguez', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-rodriguez.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    lakshadweep: {
      type: 'lakshadweep', name: 'Lakshadweep', region: this.regionsTypes.europe, flag: 'assets/drapeau/lakshadweep.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    madagascar: {
      type: 'madagascar', name: 'Madagascar', region: this.regionsTypes.europe, flag: 'assets/drapeau/madagascar.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    maldives: {
      type: 'maldives', name: 'Maldives', region: this.regionsTypes.europe, flag: 'assets/drapeau/maldives.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    mayotte: {
      type: 'mayotte', name: 'Mayotte', region: this.regionsTypes.europe, flag: 'assets/drapeau/mayotte.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    seychelles: {
      type: 'seychelles', name: 'Seychelles', region: this.regionsTypes.europe, flag: 'assets/drapeau/seychelles.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    // OCEAN PACIFIQUE
    archipelJuanFernandez: {
      type: 'archipel-juan-fernandez', name: 'Archipel Juan Fernandez', region: this.regionsTypes.europe, flag: 'assets/drapeau/archipel-juan-fernandez.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    australie: {
      type: 'australie', name: 'Australie', region: this.regionsTypes.oceanPacifique, flag: 'assets/drapeau/australie.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    fidji: {
      type: 'fidji', name: 'Fidji', region: this.regionsTypes.europe, flag: 'assets/drapeau/fidji.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    guam: {
      type: 'guam', name: 'Guam', region: this.regionsTypes.europe, flag: 'assets/drapeau/guam.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    hawai: {
      type: 'hawai', name: 'Hawaï', region: this.regionsTypes.europe, flag: 'assets/drapeau/hawai.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileNorfolk: {
      type: 'ile-norfolk', name: 'Ile Norfolk', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-norfolk.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesCook: {
      type: 'iles-cook', name: 'Iles Cook', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-cook.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesDePaques: {
      type: 'iles-de-paques', name: 'Iles de Paques', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-de-paques.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesGalapagos: {
      type: 'iles-galapagos', name: 'Iles Galapagos', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-galapagos.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesKiribati: {
      type: 'iles-kiribati', name: 'Iles Kiribati', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-kiribati.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesMariannes: {
      type: 'iles-mariannes', name: 'Iles Mariannes', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-mariannes.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesMarquises: {
      type: 'iles-marquises', name: 'Iles Marquises', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-marquises.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesMarshall: {
      type: 'iles-marshall', name: 'Iles Marshall', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-marshall.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesMidway: {
      type: 'iles-midway', name: 'Iles Midway', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-midway.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesPitcairn: {
      type: 'iles-pitcairn', name: 'Iles Pitcairn', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-pitcairn.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesSalomon: {
      type: 'iles-salomon', name: 'Iles Salomon', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-salomon.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ilesSamoa: {
      type: 'iles-samoa', name: 'Iles Samoa', region: this.regionsTypes.europe, flag: 'assets/drapeau/iles-samoa.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ileWake: {
      type: 'ile-wake', name: 'Ile Wake', region: this.regionsTypes.europe, flag: 'assets/drapeau/ile-wake.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    lordHoweIsland: {
      type: 'lord-howe-island', name: 'Lord Howe Island', region: this.regionsTypes.europe, flag: 'assets/drapeau/lord-howe-island.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    micronesie: {
      type: 'micronesie', name: 'Micronésie', region: this.regionsTypes.europe, flag: 'assets/drapeau/micronesie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nauru: {
      type: 'nauru', name: 'Nauru', region: this.regionsTypes.europe, flag: 'assets/drapeau/nauru.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    niue: {
      type: 'niue', name: 'niue', region: this.regionsTypes.europe, flag: 'assets/drapeau/niue.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nouvelleCaledonie: {
      type: 'nouvelle-caledonie', name: 'Nouvelle Caledonie', region: this.regionsTypes.europe, flag: 'assets/drapeau/nouvelle-caledonie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    nouvelleZelande: {
      type: 'nouvelle-zelande', name: 'Nouvelle-Zelande', region: this.regionsTypes.europe, flag: 'assets/drapeau/nouvelle-zelande.jpg',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ogasawara: {
      type: 'ogasawara', name: 'Ogasawara', region: this.regionsTypes.europe, flag: 'assets/drapeau/ogasawara.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    palaos: {
      type: 'palaos', name: 'Palaos', region: this.regionsTypes.europe, flag: 'assets/drapeau/palaos.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    polynesieFrancaise: {
      type: 'polynesie-francaise', name: 'Polynésie Française', region: this.regionsTypes.europe, flag: 'assets/drapeau/polynesie-francaise.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    ryukyu: {
      type: 'ryukyu', name: 'Ryukyu', region: this.regionsTypes.europe, flag: 'assets/drapeau/ryukyu.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tasmanie: {
      type: 'tasmanie', name: 'Tasmanie', region: this.regionsTypes.europe, flag: 'assets/drapeau/tasmanie.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tokelau: {
      type: 'tokelau', name: 'Tokelau', region: this.regionsTypes.europe, flag: 'assets/drapeau/tokelau.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tonga: {
      type: 'tonga', name: 'Tonga', region: this.regionsTypes.europe, flag: 'assets/drapeau/tonga.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    tuvalu: {
      type: 'tuvalu', name: 'Tuvalu', region: this.regionsTypes.europe, flag: 'assets/drapeau/tuvalu.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    vanuatu: {
      type: 'vanuatu', name: 'Vanuatu', region: this.regionsTypes.europe, flag: 'assets/drapeau/vanuatu.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
    wallisEtFutuna: {
      type: 'wallis-et-futuna', name: 'Wallis et Futuna', region: this.regionsTypes.europe, flag: 'assets/drapeau/wallis-et-futuna.png',
      informations: [{ title: 'Budget', value: '' }, { title: 'Visa ?', value: '' }, { title: 'Durée de Vol min', value: 'dscdc' }, { title: 'Nombre de points à voir', value: '' }, { title: 'Transport obligatoire', value: '' }, { title: 'Decalage horraire', value: 'ddd' }, { title: 'Killomètre à parcourir', value: '' }, { title: 'Durée minimum sur place', value: '' }, { title: 'Meilleur periode', value: '' }],
      nbPointInterest: [10, 10, 10, 10, 10, 10],
    },
  };

  private allCountries: AllCountry[] = [
    { country: this.countries.afriqueDuSud },
    { country: this.countries.algerie },
    { country: this.countries.angola },
    { country: this.countries.benin },
    { country: this.countries.botswana },
    { country: this.countries.burkinaFaso },
    { country: this.countries.burundi },
    { country: this.countries.cabinda },
    { country: this.countries.cameroun },
    { country: this.countries.tchad },
    { country: this.countries.coteDIvoire },
    { country: this.countries.djibouti },
    { country: this.countries.egypte },
    { country: this.countries.erythree },
    { country: this.countries.ethiopie },
    { country: this.countries.gabon },
    { country: this.countries.gambie },
    { country: this.countries.ghana },
    { country: this.countries.guineeBissau },
    { country: this.countries.guineeEquatoriale },
    { country: this.countries.guineeFrancaise },
    { country: this.countries.kenya },
    { country: this.countries.lesotho },
    { country: this.countries.liberie },
    { country: this.countries.libye },
    { country: this.countries.malawi },
    { country: this.countries.mali },
    { country: this.countries.maroc },
    { country: this.countries.mauritanie },
    { country: this.countries.mozambique },
    { country: this.countries.namibie },
    { country: this.countries.niger },
    { country: this.countries.nigeria },
    { country: this.countries.ouganda },
    { country: this.countries.republiqueCentrafricaine },
    { country: this.countries.republiqueDemocratiqueDuCongo },
    { country: this.countries.republiqueDuCongo },
    { country: this.countries.rwanda },
    { country: this.countries.saharaOccidental },
    { country: this.countries.saoTomeEtPrincipe },
    { country: this.countries.senegal },
    { country: this.countries.sierraLeone },
    { country: this.countries.somalie },
    { country: this.countries.somaliland },
    { country: this.countries.soudan },
    { country: this.countries.soudanDuSud },
    { country: this.countries.swaziland },
    { country: this.countries.tanzanie },
    { country: this.countries.togo },
    { country: this.countries.tunisie },
    { country: this.countries.zambie },
    { country: this.countries.zimbabwe },
    { country: this.countries.alaska },
    { country: this.countries.canada },
    { country: this.countries.etatsUnis },
    { country: this.countries.ilePrinceEdward },
    { country: this.countries.mexique },
    { country: this.countries.stPierreEtMiquelon },
    { country: this.countries.argentine },
    { country: this.countries.bolivie },
    { country: this.countries.bresil },
    { country: this.countries.chili },
    { country: this.countries.colombie },
    { country: this.countries.equateur },
    { country: this.countries.guyane },
    { country: this.countries.guyaneFrancaise },
    { country: this.countries.paraguay },
    { country: this.countries.peru },
    { country: this.countries.suriname },
    { country: this.countries.uruguay },
    { country: this.countries.venezuela },
    { country: this.countries.belize },
    { country: this.countries.costaRica },
    { country: this.countries.elSalvador },
    { country: this.countries.guatemala },
    { country: this.countries.honduras },
    { country: this.countries.nicaragua },
    { country: this.countries.panama },
    { country: this.countries.abkhazia },
    { country: this.countries.afghanistan },
    { country: this.countries.armenie },
    { country: this.countries.azerbaidjan },
    { country: this.countries.bangladesh },
    { country: this.countries.bhoutan },
    { country: this.countries.brunei },
    { country: this.countries.cambodge },
    { country: this.countries.chine },
    { country: this.countries.coreeDuNord },
    { country: this.countries.coreeDuSud },
    { country: this.countries.georgie },
    { country: this.countries.inde },
    { country: this.countries.indonesie },
    { country: this.countries.japon },
    { country: this.countries.kazakhstan },
    { country: this.countries.kirghizistan },
    { country: this.countries.laos },
    { country: this.countries.malaisie },
    { country: this.countries.mongolie },
    { country: this.countries.myanmar },
    { country: this.countries.nakhchivan },
    { country: this.countries.nepal },
    { country: this.countries.pakistan },
    { country: this.countries.papouasie },
    { country: this.countries.philippines },
    { country: this.countries.siberie },
    { country: this.countries.singapour },
    { country: this.countries.sriLanka },
    { country: this.countries.tadjikistan },
    { country: this.countries.taiwan },
    { country: this.countries.thailande },
    { country: this.countries.tibet },
    { country: this.countries.timorLeste },
    { country: this.countries.turkmenistan },
    { country: this.countries.ouzbekistan },
    { country: this.countries.vietnam },
    { country: this.countries.anguilla },
    { country: this.countries.antigua },
    { country: this.countries.aruba },
    { country: this.countries.bahamas },
    { country: this.countries.barbade },
    { country: this.countries.bonaire },
    { country: this.countries.cuba },
    { country: this.countries.curacao },
    { country: this.countries.dominique },
    { country: this.countries.grenade },
    { country: this.countries.guadeloupe },
    { country: this.countries.haiti },
    { country: this.countries.ilesCaimans },
    { country: this.countries.ilesLeeward },
    { country: this.countries.ilesTurquesEtCaiques },
    { country: this.countries.ileVierge },
    { country: this.countries.jamaique },
    { country: this.countries.martinique },
    { country: this.countries.montserrat },
    { country: this.countries.puertoRico },
    { country: this.countries.republiqueDominicaine },
    { country: this.countries.sanAndresEtProvidencia },
    { country: this.countries.dominique },
    { country: this.countries.stBarth },
    { country: this.countries.stChristopheEtNieves },
    { country: this.countries.stMartin },
    { country: this.countries.stVincentEtDeps },
    { country: this.countries.trinidadEtTobago },
    { country: this.countries.albanie },
    { country: this.countries.allemagne },
    { country: this.countries.andorre },
    { country: this.countries.angleterre },
    { country: this.countries.autriche },
    { country: this.countries.belgique },
    { country: this.countries.bielorussie },
    { country: this.countries.bosnieHerzegovine },
    { country: this.countries.bulgarie },
    { country: this.countries.chypre },
    { country: this.countries.crete },
    { country: this.countries.croatie },
    { country: this.countries.danemark },
    { country: this.countries.ecosse },
    { country: this.countries.espagne },
    { country: this.countries.estonie },
    { country: this.countries.finlande },
    { country: this.countries.france },
    { country: this.countries.gibraltar },
    { country: this.countries.grece },
    { country: this.countries.hongrie },
    { country: this.countries.ilesAngloNormandes },
    { country: this.countries.ileDuMan },
    { country: this.countries.irlande },
    { country: this.countries.italie },
    { country: this.countries.kosovo },
    { country: this.countries.liechtenstein },
    { country: this.countries.lettonie },
    { country: this.countries.lituanie },
    { country: this.countries.luxembourg },
    { country: this.countries.macedoine },
    { country: this.countries.malte },
    { country: this.countries.moldavie },
    { country: this.countries.monaco },
    { country: this.countries.montenegro },
    { country: this.countries.norvege },
    { country: this.countries.paysBas },
    { country: this.countries.paysDeGalles },
    { country: this.countries.pologne },
    { country: this.countries.portugal },
    { country: this.countries.republiqueTcheque },
    { country: this.countries.roumanie },
    { country: this.countries.russie },
    { country: this.countries.serbie },
    { country: this.countries.slovaquie },
    { country: this.countries.slovenie },
    { country: this.countries.suede },
    { country: this.countries.suisse },
    { country: this.countries.turquie },
    { country: this.countries.ukraine },
    { country: this.countries.abuDhabi },
    { country: this.countries.ajman },
    { country: this.countries.arabieSaoudite },
    { country: this.countries.bahrein },
    { country: this.countries.charjah },
    { country: this.countries.dubai },
    { country: this.countries.fujairah },
    { country: this.countries.iran },
    { country: this.countries.irak },
    { country: this.countries.israel },
    { country: this.countries.jordanie },
    { country: this.countries.koweit },
    { country: this.countries.liban },
    { country: this.countries.oman },
    { country: this.countries.oummAlQaiwain },
    { country: this.countries.palestine },
    { country: this.countries.qatar },
    { country: this.countries.rasAlKhaimah },
    { country: this.countries.syrie },
    { country: this.countries.yemen },
    { country: this.countries.ascension },
    { country: this.countries.capVert },
    { country: this.countries.fernandoDoNoronha },
    { country: this.countries.groenland },
    { country: this.countries.ileDesBermudes },
    { country: this.countries.ileFeroe },
    { country: this.countries.ilesAcores },
    { country: this.countries.ilesCanaries },
    { country: this.countries.ilesFalkland },
    { country: this.countries.islande },
    { country: this.countries.madeira },
    { country: this.countries.stHelena },
    { country: this.countries.tristanDaCunha },
    { country: this.countries.andamanIlesNicobar },
    { country: this.countries.ileCocos },
    { country: this.countries.ileDeLaReunion },
    { country: this.countries.ileMaurice },
    { country: this.countries.ilesChristmas },
    { country: this.countries.ilesComores },
    { country: this.countries.ilesRodriguez },
    { country: this.countries.lakshadweep },
    { country: this.countries.madagascar },
    { country: this.countries.maldives },
    { country: this.countries.mayotte },
    { country: this.countries.seychelles },
    { country: this.countries.archipelJuanFernandez },
    { country: this.countries.australie },
    { country: this.countries.fidji },
    { country: this.countries.guam },
    { country: this.countries.hawai },
    { country: this.countries.ileNorfolk },
    { country: this.countries.ilesCook },
    { country: this.countries.ilesDePaques },
    { country: this.countries.ilesGalapagos },
    { country: this.countries.ilesKiribati },
    { country: this.countries.ilesMariannes },
    { country: this.countries.ilesMarquises },
    { country: this.countries.ilesMarshall },
    { country: this.countries.ilesMidway },
    { country: this.countries.ilesPitcairn },
    { country: this.countries.ilesSalomon },
    { country: this.countries.ilesSamoa },
    { country: this.countries.ileWake },
    { country: this.countries.lordHoweIsland },
    { country: this.countries.micronesie },
    { country: this.countries.nauru },
    { country: this.countries.niue },
    { country: this.countries.nouvelleCaledonie },
    { country: this.countries.nouvelleZelande },
    { country: this.countries.ogasawara },
    { country: this.countries.palaos },
    { country: this.countries.polynesieFrancaise },
    { country: this.countries.ryukyu },
    { country: this.countries.tasmanie },
    { country: this.countries.tokelau },
    { country: this.countries.tonga },
    { country: this.countries.tuvalu },
    { country: this.countries.vanuatu },
    { country: this.countries.wallisEtFutuna },
  ];
  private africanCountries: AfricanCountry[] = [
    { country: this.countries.afriqueDuSud },
    { country: this.countries.algerie },
    { country: this.countries.angola },
    { country: this.countries.benin },
    { country: this.countries.botswana },
    { country: this.countries.burkinaFaso },
    { country: this.countries.burundi },
    { country: this.countries.cabinda },
    { country: this.countries.cameroun },
    { country: this.countries.tchad },
    { country: this.countries.coteDIvoire },
    { country: this.countries.djibouti },
    { country: this.countries.egypte },
    { country: this.countries.erythree },
    { country: this.countries.ethiopie },
    { country: this.countries.gabon },
    { country: this.countries.gambie },
    { country: this.countries.ghana },
    { country: this.countries.guineeBissau },
    { country: this.countries.guineeEquatoriale },
    { country: this.countries.guineeFrancaise },
    { country: this.countries.kenya },
    { country: this.countries.lesotho },
    { country: this.countries.liberie },
    { country: this.countries.libye },
    { country: this.countries.malawi },
    { country: this.countries.mali },
    { country: this.countries.maroc },
    { country: this.countries.mauritanie },
    { country: this.countries.mozambique },
    { country: this.countries.namibie },
    { country: this.countries.niger },
    { country: this.countries.nigeria },
    { country: this.countries.ouganda },
    { country: this.countries.republiqueCentrafricaine },
    { country: this.countries.republiqueDemocratiqueDuCongo },
    { country: this.countries.republiqueDuCongo },
    { country: this.countries.rwanda },
    { country: this.countries.saharaOccidental },
    { country: this.countries.saoTomeEtPrincipe },
    { country: this.countries.senegal },
    { country: this.countries.sierraLeone },
    { country: this.countries.somalie },
    { country: this.countries.somaliland },
    { country: this.countries.soudan },
    { country: this.countries.soudanDuSud },
    { country: this.countries.swaziland },
    { country: this.countries.tanzanie },
    { country: this.countries.togo },
    { country: this.countries.tunisie },
    { country: this.countries.zambie },
    { country: this.countries.zimbabwe },
  ];
  private americanCountries: AmericanCountry[] = [
    { country: this.countries.alaska },
    { country: this.countries.canada },
    { country: this.countries.etatsUnis },
    { country: this.countries.ilePrinceEdward },
    { country: this.countries.mexique },
    { country: this.countries.stPierreEtMiquelon },
    { country: this.countries.argentine },
    { country: this.countries.bolivie },
    { country: this.countries.bresil },
    { country: this.countries.chili },
    { country: this.countries.colombie },
    { country: this.countries.equateur },
    { country: this.countries.guyane },
    { country: this.countries.guyaneFrancaise },
    { country: this.countries.paraguay },
    { country: this.countries.peru },
    { country: this.countries.suriname },
    { country: this.countries.uruguay },
    { country: this.countries.venezuela },
    { country: this.countries.belize },
    { country: this.countries.costaRica },
    { country: this.countries.elSalvador },
    { country: this.countries.guatemala },
    { country: this.countries.honduras },
    { country: this.countries.nicaragua },
    { country: this.countries.panama },
  ];
  private northAmericanCountries: NorthAmericanCountry[] = [
    { country: this.countries.alaska },
    { country: this.countries.canada },
    { country: this.countries.etatsUnis },
    { country: this.countries.ilePrinceEdward },
    { country: this.countries.mexique },
    { country: this.countries.stPierreEtMiquelon },
  ];
  private southAmericanCountries: SouthAmericanCountry[] = [
    { country: this.countries.argentine },
    { country: this.countries.bolivie },
    { country: this.countries.bresil },
    { country: this.countries.chili },
    { country: this.countries.colombie },
    { country: this.countries.equateur },
    { country: this.countries.guyane },
    { country: this.countries.guyaneFrancaise },
    { country: this.countries.paraguay },
    { country: this.countries.peru },
    { country: this.countries.suriname },
    { country: this.countries.uruguay },
    { country: this.countries.venezuela },
  ];
  private centraleAmericanCountries: CentraleAmericanCountry[] = [
    { country: this.countries.belize },
    { country: this.countries.costaRica },
    { country: this.countries.elSalvador },
    { country: this.countries.guatemala },
    { country: this.countries.honduras },
    { country: this.countries.nicaragua },
    { country: this.countries.panama },
  ];
  private asianCountries: AsianCountry[] = [
    { country: this.countries.abkhazia },
    { country: this.countries.afghanistan },
    { country: this.countries.armenie },
    { country: this.countries.azerbaidjan },
    { country: this.countries.bangladesh },
    { country: this.countries.bhoutan },
    { country: this.countries.brunei },
    { country: this.countries.cambodge },
    { country: this.countries.chine },
    { country: this.countries.coreeDuNord },
    { country: this.countries.coreeDuSud },
    { country: this.countries.georgie },
    { country: this.countries.inde },
    { country: this.countries.indonesie },
    { country: this.countries.japon },
    { country: this.countries.kazakhstan },
    { country: this.countries.kirghizistan },
    { country: this.countries.laos },
    { country: this.countries.malaisie },
    { country: this.countries.mongolie },
    { country: this.countries.myanmar },
    { country: this.countries.nakhchivan },
    { country: this.countries.nepal },
    { country: this.countries.pakistan },
    { country: this.countries.papouasie },
    { country: this.countries.philippines },
    { country: this.countries.siberie },
    { country: this.countries.singapour },
    { country: this.countries.sriLanka },
    { country: this.countries.tadjikistan },
    { country: this.countries.taiwan },
    { country: this.countries.thailande },
    { country: this.countries.tibet },
    { country: this.countries.timorLeste },
    { country: this.countries.turkmenistan },
    { country: this.countries.ouzbekistan },
    { country: this.countries.vietnam },
  ];
  private caraibeanCountries: CaraibeanCountry[] = [
    { country: this.countries.anguilla },
    { country: this.countries.antigua },
    { country: this.countries.aruba },
    { country: this.countries.bahamas },
    { country: this.countries.barbade },
    { country: this.countries.bonaire },
    { country: this.countries.cuba },
    { country: this.countries.curacao },
    { country: this.countries.dominique },
    { country: this.countries.grenade },
    { country: this.countries.guadeloupe },
    { country: this.countries.haiti },
    { country: this.countries.ilesCaimans },
    { country: this.countries.ilesLeeward },
    { country: this.countries.ilesTurquesEtCaiques },
    { country: this.countries.ileVierge },
    { country: this.countries.jamaique },
    { country: this.countries.martinique },
    { country: this.countries.montserrat },
    { country: this.countries.puertoRico },
    { country: this.countries.republiqueDominicaine },
    { country: this.countries.sanAndresEtProvidencia },
    { country: this.countries.dominique },
    { country: this.countries.stBarth },
    { country: this.countries.stChristopheEtNieves },
    { country: this.countries.stMartin },
    { country: this.countries.stVincentEtDeps },
    { country: this.countries.trinidadEtTobago },
  ];
  private europeanCountries: EuropeanCountry[] = [
    { country: this.countries.albanie },
    { country: this.countries.allemagne },
    { country: this.countries.andorre },
    { country: this.countries.angleterre },
    { country: this.countries.autriche },
    { country: this.countries.belgique },
    { country: this.countries.bielorussie },
    { country: this.countries.bosnieHerzegovine },
    { country: this.countries.bulgarie },
    { country: this.countries.chypre },
    { country: this.countries.crete },
    { country: this.countries.croatie },
    { country: this.countries.danemark },
    { country: this.countries.ecosse },
    { country: this.countries.espagne },
    { country: this.countries.estonie },
    { country: this.countries.finlande },
    { country: this.countries.france },
    { country: this.countries.gibraltar },
    { country: this.countries.grece },
    { country: this.countries.hongrie },
    { country: this.countries.ilesAngloNormandes },
    { country: this.countries.ileDuMan },
    { country: this.countries.irlande },
    { country: this.countries.italie },
    { country: this.countries.kosovo },
    { country: this.countries.liechtenstein },
    { country: this.countries.lettonie },
    { country: this.countries.lituanie },
    { country: this.countries.luxembourg },
    { country: this.countries.macedoine },
    { country: this.countries.malte },
    { country: this.countries.moldavie },
    { country: this.countries.monaco },
    { country: this.countries.montenegro },
    { country: this.countries.norvege },
    { country: this.countries.paysBas },
    { country: this.countries.paysDeGalles },
    { country: this.countries.pologne },
    { country: this.countries.portugal },
    { country: this.countries.republiqueTcheque },
    { country: this.countries.roumanie },
    { country: this.countries.russie },
    { country: this.countries.serbie },
    { country: this.countries.slovaquie },
    { country: this.countries.slovenie },
    { country: this.countries.suede },
    { country: this.countries.suisse },
    { country: this.countries.turquie },
    { country: this.countries.ukraine },
  ];
  private moyenOrientCountries: MoyenOrientCountry[] = [
    { country: this.countries.abuDhabi },
    { country: this.countries.ajman },
    { country: this.countries.arabieSaoudite },
    { country: this.countries.bahrein },
    { country: this.countries.charjah },
    { country: this.countries.dubai },
    { country: this.countries.fujairah },
    { country: this.countries.iran },
    { country: this.countries.irak },
    { country: this.countries.israel },
    { country: this.countries.jordanie },
    { country: this.countries.koweit },
    { country: this.countries.liban },
    { country: this.countries.oman },
    { country: this.countries.oummAlQaiwain },
    { country: this.countries.palestine },
    { country: this.countries.qatar },
    { country: this.countries.rasAlKhaimah },
    { country: this.countries.syrie },
    { country: this.countries.yemen },
  ];
  private oceanAtlantiqueCountries: OceanAtlantiqueCountry[] = [
    { country: this.countries.ascension },
    { country: this.countries.capVert },
    { country: this.countries.fernandoDoNoronha },
    { country: this.countries.groenland },
    { country: this.countries.ileDesBermudes },
    { country: this.countries.ileFeroe },
    { country: this.countries.ilesAcores },
    { country: this.countries.ilesCanaries },
    { country: this.countries.ilesFalkland },
    { country: this.countries.islande },
    { country: this.countries.madeira },
    { country: this.countries.stHelena },
    { country: this.countries.tristanDaCunha },
  ];
  private oceanIndienCountries: OceanIndienCountry[] = [
    { country: this.countries.andamanIlesNicobar },
    { country: this.countries.ileCocos },
    { country: this.countries.ileDeLaReunion },
    { country: this.countries.ileMaurice },
    { country: this.countries.ilesChristmas },
    { country: this.countries.ilesComores },
    { country: this.countries.ilesRodriguez },
    { country: this.countries.lakshadweep },
    { country: this.countries.madagascar },
    { country: this.countries.maldives },
    { country: this.countries.mayotte },
    { country: this.countries.seychelles },
  ];
  private oceanPacifiqueCountries: OceanPacifiqueCountry[] = [
    { country: this.countries.archipelJuanFernandez },
    { country: this.countries.australie },
    { country: this.countries.fidji },
    { country: this.countries.guam },
    { country: this.countries.hawai },
    { country: this.countries.ileNorfolk },
    { country: this.countries.ilesCook },
    { country: this.countries.ilesDePaques },
    { country: this.countries.ilesGalapagos },
    { country: this.countries.ilesKiribati },
    { country: this.countries.ilesMariannes },
    { country: this.countries.ilesMarquises },
    { country: this.countries.ilesMarshall },
    { country: this.countries.ilesMidway },
    { country: this.countries.ilesPitcairn },
    { country: this.countries.ilesSalomon },
    { country: this.countries.ilesSamoa },
    { country: this.countries.ileWake },
    { country: this.countries.lordHoweIsland },
    { country: this.countries.micronesie },
    { country: this.countries.nauru },
    { country: this.countries.niue },
    { country: this.countries.nouvelleCaledonie },
    { country: this.countries.nouvelleZelande },
    { country: this.countries.ogasawara },
    { country: this.countries.palaos },
    { country: this.countries.polynesieFrancaise },
    { country: this.countries.ryukyu },
    { country: this.countries.tasmanie },
    { country: this.countries.tokelau },
    { country: this.countries.tonga },
    { country: this.countries.tuvalu },
    { country: this.countries.vanuatu },
    { country: this.countries.wallisEtFutuna },
  ];
  getCountries(): Observable<any> {
    return observableOf(this.countries);
  }
  getAllCountry(): Observable<AllCountry[]> {
    return observableOf(this.allCountries);
  }
  getAfricanCountry(): Observable<AfricanCountry[]> {
    return observableOf(this.africanCountries);
  }
  getAmericanCountry(): Observable<AmericanCountry[]> {
    return observableOf(this.americanCountries);
  }
  getNorthAmericanCountry(): Observable<NorthAmericanCountry[]> {
    return observableOf(this.northAmericanCountries);
  }
  getSouthAmericanCountry(): Observable<SouthAmericanCountry[]> {
    return observableOf(this.southAmericanCountries);
  }
  getCentraleAmericanCountry(): Observable<CentraleAmericanCountry[]> {
    return observableOf(this.centraleAmericanCountries);
  }
  getAsianCountry(): Observable<AsianCountry[]> {
    return observableOf(this.asianCountries);
  }
  getCaraibeanCountry(): Observable<CaraibeanCountry[]> {
    return observableOf(this.caraibeanCountries);
  }
  getEuropeanCountry(): Observable<EuropeanCountry[]> {
    return observableOf(this.europeanCountries);
  }
  getMoyenOrientCountry(): Observable<MoyenOrientCountry[]> {
    return observableOf(this.moyenOrientCountries);
  }
  getOceanAtlantiqueCountry(): Observable<OceanAtlantiqueCountry[]> {
    return observableOf(this.oceanAtlantiqueCountries);
  }
  getOceanIndienCountry(): Observable<OceanIndienCountry[]> {
    return observableOf(this.oceanIndienCountries);
  }
  getOceanPacifiqueCountry(): Observable<OceanPacifiqueCountry[]> {
    return observableOf(this.oceanPacifiqueCountries);
  }

  changeNameToType(name: string): string {
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.name === name) return this.allCountries[i].country.type;
    }
  }

  changeTypeToName(type: string): string {
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.type === type) return this.allCountries[i].country.name;
    }
  }

  getCountryInformation(name: string): Observable<Information[]> {
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.name === name) return observableOf(this.allCountries[i].country.informations);
    }
  }

  getCountriesCategoriesData(name: string): Observable<number[]> {
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.name === name) return observableOf(this.allCountries[i].country.nbPointInterest);
    }
  }
}

