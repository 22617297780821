import { Observable } from 'rxjs';

export interface Picture {
  title: string;
  source: string;
}

export interface CountryPictures {
  type: string;
  name: string;
  region: string;
  pictures: Picture[];
}

export interface AllCountryPictures {
  country: CountryPictures;
}

export abstract class CountryPicturesData {
  abstract getCamerasData(): Observable<Picture[]>;
  //abstract getCountryPictures(countryName: string): Observable<Picture[]>;
}
