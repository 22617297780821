import { Observable } from 'rxjs';

export interface Country {
  type: string;
  name: string;
  region: string;
  flag: string;
  informations: Information[];
  nbPointInterest: number[];
}

export interface Information {
  title: string;
  value: any;
}

export interface AllCountry {
  country: Country;
}
export interface AfricanCountry {
  country: Country;
}
export interface AmericanCountry {
  country: Country;
}
export interface NorthAmericanCountry {
  country: Country;
}
export interface SouthAmericanCountry {
  country: Country;
}
export interface CentraleAmericanCountry {
  country: Country;
}
export interface AsianCountry {
  country: Country;
}
export interface CaraibeanCountry {
  country: Country;
}
export interface EuropeanCountry {
  country: Country;
}
export interface MoyenOrientCountry {
  country: Country;
}
export interface OceanAtlantiqueCountry {
  country: Country;
}
export interface OceanIndienCountry {
  country: Country;
}
export interface OceanPacifiqueCountry {
  country: Country;
}
export abstract class CountryData {
  abstract getCountries(): Observable<Country[]>;
  abstract getAllCountry(): Observable<AllCountry[]>;
  abstract getAfricanCountry(): Observable<AfricanCountry[]>;
  abstract getAmericanCountry(): Observable<AmericanCountry[]>;
  abstract getNorthAmericanCountry(): Observable<NorthAmericanCountry[]>;
  abstract getSouthAmericanCountry(): Observable<SouthAmericanCountry[]>;
  abstract getCentraleAmericanCountry(): Observable<CentraleAmericanCountry[]>;
  abstract getAsianCountry(): Observable<AsianCountry[]>;
  abstract getCaraibeanCountry(): Observable<CaraibeanCountry[]>;
  abstract getEuropeanCountry(): Observable<EuropeanCountry[]>;
  abstract getMoyenOrientCountry(): Observable<MoyenOrientCountry[]>;
  abstract getOceanAtlantiqueCountry(): Observable<OceanAtlantiqueCountry[]>;
  abstract getOceanIndienCountry(): Observable<OceanIndienCountry[]>;
  abstract getOceanPacifiqueCountry(): Observable<OceanPacifiqueCountry[]>;

  abstract getCountryInformation(name: string): Observable<Information[]>;
  abstract getCountriesCategoriesData(name: string): Observable<number[]>;
}
