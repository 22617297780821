import { AllCountryPictures } from './../data/country-picture';
import { Injectable } from '@angular/core';
import { of as observableOf, Observable, Subject } from 'rxjs';
import { Picture, CountryPicturesData } from '../data/country-picture';

@Injectable()
export class CountryPicturesService extends CountryPicturesData {

  public countryName: string;
  public countryNameChanges: Subject<string> = new Subject<string>();

  public cameras: Picture[] = [
    {
      title: 'Camera #1',
      source: 'assets/images/camera1.jpg',
    },
    {
      title: 'Camera #2',
      source: 'assets/images/camera2.jpg',
    },
    {
      title: 'Camera #3',
      source: 'assets/images/camera3.jpg',
    },
    {
      title: 'Camera #4',
      source: 'assets/images/camera4.jpg',
    },
  ];

  public camerasChanges: Subject<Picture[]> = new Subject<Picture[]>();

  private regionsTypes = {
    afrique: 'Afrique',
    amerique: 'Amerique',
    asie: 'Asie',
    caraibes: 'Caraibes',
    europe: 'Europe',
    moyentOrient: 'Moyent-Orient',
    oceanAtlantique: 'Océan Atlantique',
    oceanIndien: 'Océan Indien',
    oceanPacifique: 'Océan Pacifique',

  };

  private countries = {
    // AFRIQUE
    afriqueDuSud: { type: 'afrique-du-sud', name: 'Afrique du Sud', region: this.regionsTypes.afrique,
      pictures: [{title: 'Camera #1', source: 'assets/images/afrique-du-sud/best1.jpg',}, {title: 'Camera #2', source: 'assets/images/afrique-du-sud/best2.jpeg', }, {title: 'Camera #3', source: 'assets/images/afrique-du-sud/best3.jpg', }, {title: 'afrique-du-sud/best #4', source: 'assets/images/afrique-du-sud/best4.jpg',}],
    },
    algerie: { type: 'algerie', name: 'Algérie', region: this.regionsTypes.afrique,
      pictures: [{title: 'Camera #1', source: 'assets/images/algerie/best1.jpg'}, {title: 'best #2', source: 'assets/images/algerie/best2.jpg' }, {title: 'best #3', source: 'assets/images/algerie/best3.jpg' }, {title: 'best #4', source: 'assets/images/algerie/best4.jpg'}],
    },
    angola: { type: 'angola', name: 'Angola', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    benin: { type: 'benin', name: 'Bénin', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/benin/best1.jpg'}, {title: 'best #2', source: 'assets/images/benin/best2.jpg' }, {title: 'best #3', source: 'assets/images/benin/best3.jpg' }, {title: 'best #4', source: 'assets/images/benin/best4.jpg'}],
    },
    botswana: { type: 'botswana', name: 'Botswana', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/botswana/best1.jpg'}, {title: 'best #2', source: 'assets/images/botswana/best2.jpg' }, {title: 'best #3', source: 'assets/images/botswana/best3.jpg' }, {title: 'best #4', source: 'assets/images/botswana/best4.jpg'}],
    },
    burkinaFaso: { type: 'burkina-faso', name: 'Burkina Faso', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/burkina-faso/best1.jpg'}, {title: 'best #2', source: 'assets/images/burkina-faso/best2.jpg' }, {title: 'best #3', source: 'assets/images/burkina-faso/best3.jpg' }, {title: 'best #4', source: 'assets/images/burkina-faso/best4.jpg'}],
    },
    burundi: { type: 'burundi', name: 'Burundi', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/burundi/best1.jpg'}, {title: 'best #2', source: 'assets/images/burundi/best2.jpg' }, {title: 'best #3', source: 'assets/images/burundi/best3.jpg' }, {title: 'best #4', source: 'assets/images/burundi/best4.jpg'}],
    },
    cabinda: { type: 'cabinda', name: 'Cabinda', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/cabinda/best1.jpg'}, {title: 'best #2', source: 'assets/images/cabinda/best2.jpg' }, {title: 'best #3', source: 'assets/images/cabinda/best3.jpg' }, {title: 'best #4', source: 'assets/images/cabinda/best4.jpg'}],
    },
    cameroun: { type: 'cameroun', name: 'Cameroun', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/cameroun/best1.jpg'}, {title: 'best #2', source: 'assets/images/cameroun/best2.jpg' }, {title: 'best #3', source: 'assets/images/cameroun/best3.jpg' }, {title: 'best #4', source: 'assets/images/cameroun/best4.jpg'}],
    },
    tchad: { type: 'tchad', name: 'Tchad', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/tchad/best1.jpg'}, {title: 'best #2', source: 'assets/images/tchad/best2.jpg' }, {title: 'best #3', source: 'assets/images/tchad/best3.jpg' }, {title: 'best #4', source: 'assets/images/tchad/best4.jpg'}],
    },
    coteDIvoire: { type: 'cote-d-ivoire', name: 'Cote d\'Ivoire', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/cote-d-ivoire/best1.jpg'}, {title: 'best #2', source: 'assets/images/cote-d-ivoire/best2.jpg' }, {title: 'best #3', source: 'assets/images/cote-d-ivoire/best3.jpg' }, {title: 'best #4', source: 'assets/images/cote-d-ivoire/best4.jpg'}],
    },
    djibouti: { type: 'djibouti', name: 'Djibouti', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/djibouti/best1.jpg'}, {title: 'best #2', source: 'assets/images/djibouti/best2.jpg' }, {title: 'best #3', source: 'assets/images/djibouti/best3.jpg' }, {title: 'best #4', source: 'assets/images/djibouti/best4.jpg'}],
    },
    egypte: { type: 'egypte', name: 'Egypte', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/egypte/best1.jpg'}, {title: 'best #2', source: 'assets/images/egypte/best2.jpg' }, {title: 'best #3', source: 'assets/images/egypte/best3.jpg' }, {title: 'best #4', source: 'assets/images/egypte/best4.jpg'}],
    },
    erythree: { type: 'erythree', name: 'Erythrée', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/erythree/best1.jpg'}, {title: 'best #2', source: 'assets/images/erythree/best2.jpg' }, {title: 'best #3', source: 'assets/images/erythree/best3.jpg' }, {title: 'best #4', source: 'assets/images/erythree/best4.jpg'}],
    },
    ethiopie: { type: 'ethiopie', name: 'Ethiopie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/ethiopie/best1.jpg'}, {title: 'best #2', source: 'assets/images/ethiopie/best2.jpg' }, {title: 'best #3', source: 'assets/images/ethiopie/best3.jpg' }, {title: 'best #4', source: 'assets/images/ethiopie/best4.jpg'}],
    },
    gabon: { type: 'gabon', name: 'Gabon', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/gabon/best1.jpg'}, {title: 'best #2', source: 'assets/images/gabon/best2.jpg' }, {title: 'best #3', source: 'assets/images/gabon/best3.jpg' }, {title: 'best #4', source: 'assets/images/gabon/best4.jpg'}],
    },
    gambie: { type: 'gambie', name: 'Gambie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ghana: { type: 'ghana', name: 'Ghana', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guineeBissau: { type: 'guinee-bissau', name: 'Guinée Bissau', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guineeEquatoriale: { type: 'guinee-equatoriale', name: 'Guinée Equatoriale', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guineeFrancaise: { type: 'guinee-francaise', name: 'Guinée Francaise', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    kenya: { type: 'kenya', name: 'Kenya', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    lesotho: { type: 'lesotho', name: 'Lesotho', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    liberie: { type: 'liberie', name: 'Liberie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    libye: { type: 'libye', name: 'Libye', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    malawi: { type: 'malawi', name: 'Malawi', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mali: { type: 'mali', name: 'Mali', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    maroc: { type: 'maroc', name: 'Maroc', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mauritanie: { type: 'mauritanie', name: 'Mauritanie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mozambique: { type: 'mozambique', name: 'Mozambique', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    namibie: { type: 'namibie', name: 'Namibie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    niger: { type: 'niger', name: 'Niger', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nigeria: { type: 'nigeria', name: 'Nigeria', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ouganda: { type: 'ouganda', name: 'Ouganda', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    republiqueCentrafricaine: { type: 'republique-centrafricaine', name: 'République Centrafricaine', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    republiqueDemocratiqueDuCongo: { type: 'republique-democratique-du-congo', name: 'République Démocratique du Congo', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    republiqueDuCongo: { type: 'republique-du-congo', name: 'République du Congo', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    rwanda: { type: 'rwanda', name: 'Rwanda', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    saharaOccidental: { type: 'sahara-occidental', name: 'Sahara Occidental', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    saoTomeEtPrincipe: { type: 'sao-tome-et-principe', name: 'Sao Tome et Principe', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    senegal: { type: 'senegal', name: 'Sénégal', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    sierraLeone: { type: 'sierra-leone', name: 'Sierra Leone', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    somalie: { type: 'somalie', name: 'Somalie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    somaliland: { type: 'somaliland', name: 'Somaliland', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    soudan: { type: 'soudan', name: 'Soudan', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    soudanDuSud: { type: 'soudan-du-sud', name: 'Soudan du Sud', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    swaziland: { type: 'swaziland', name: 'Swaziland', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tanzanie: { type: 'tanzanie', name: 'Tanzanie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    togo: { type: 'togo', name: 'Togo', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tunisie: { type: 'tunisie', name: 'Tunisie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    zambie: { type: 'zambie', name: 'Zambie', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    zimbabwe: { type: 'zimbabwe', name: 'Zimbabwe', region: this.regionsTypes.afrique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // AMERIQUE
        // AMERIQUE DU NORD
    alaska: { type: 'alaska', name: 'Alaska', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    canada: { type: 'canada', name: 'Canada', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    etatsUnis: { type: 'etats-unis', name: 'Etats Unis', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilePrinceEdward: { type: 'ile-prince-edward', name: 'Ile Prince Edward', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mexique: { type: 'mexique', name: 'Mexique', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stPierreEtMiquelon: { type: 'st-pierre-et-miquelon', name: 'St Pierre et Miquelon', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
        // AMERIQUE DU SUD
    argentine: { type: 'argentine', name: 'Argentine', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bolivie: { type: 'bolivie', name: 'Bolivie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bresil: { type: 'bresil', name: 'Bresil', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    chili: { type: 'chili', name: 'Chili', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    colombie: { type: 'colombie', name: 'Colombie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    equateur: { type: 'equateur', name: 'Equateur', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guyane: { type: 'guyane', name: 'Guyane', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guyaneFrancaise: { type: 'guyane-francaise', name: 'Guyane Française', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    paraguay: { type: 'paraguay', name: 'Paraguay', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    peru: { type: 'peru', name: 'Peru', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    suriname: { type: 'suriname', name: 'Suriname', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    uruguay: { type: 'uruguay', name: 'Uruguay', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    venezuela: { type: 'venezuela', name: 'Venezuela', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
        // AMERIQUE CENTRALE
    belize: { type: 'belize', name: 'Belize', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    costaRica: { type: 'costa-rica', name: 'Costa Rica', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    elSalvador: { type: 'el-salvador', name: 'El Salvador', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guatemala: { type: 'guatemala', name: 'Guatemala', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    honduras: { type: 'honduras', name: 'Honduras', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nicaragua: { type: 'nicaragua', name: 'Nicaragua', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    panama: { type: 'panama', name: 'Panama', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
        // ASIE
    abkhazia: { type: 'abkhazia', name: 'Abkhazia', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    afghanistan: { type: 'afghanistan', name: 'Afghanistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    armenie: { type: 'armenie', name: 'Armenie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    azerbaidjan: { type: 'azerbaidjan', name: 'Azerbaïdjan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bangladesh: { type: 'bangladesh', name: 'Bangladesh', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bhoutan: { type: 'bhoutan', name: 'Bhoutan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    brunei: { type: 'brunei', name: 'Brunei', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    cambodge: { type: 'cambodge', name: 'Cambodge', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    chine: { type: 'chine', name: 'Chine', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    coreeDuNord: { type: 'coree-du-nord', name: 'Corée du Nord', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    coreeDuSud: { type: 'coree-du-sud', name: 'Corée du sud', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    georgie: { type: 'georgie', name: 'Georgie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    inde: { type: 'inde', name: 'Inde', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    indonesie: { type: 'indonesie', name: 'Indonésie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    japon: { type: 'japon', name: 'Japon', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    kazakhstan: { type: 'kazakhstan', name: 'Kazakhstan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    kirghizistan: { type: 'kirghizistan', name: 'Kirghizistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    laos: { type: 'laos', name: 'Laos', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    malaisie: { type: 'malaisie', name: 'Malaisie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mongolie: { type: 'mongolie', name: 'Mongolie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    myanmar: { type: 'myanmar', name: 'Myanmar', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nakhchivan: { type: 'nakhchivan', name: 'Nakhchivan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nepal: { type: 'nepal', name: 'Népal', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ouzbekistan: { type: 'ouzbekistan', name: 'Ouzbékistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    pakistan: { type: 'pakistan', name: 'Pakistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    papouasie: { type: 'papouasie', name: 'Papouasie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    philippines: { type: 'philippines', name: 'Philippines', region: this.regionsTypes.asie,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    siberie: { type: 'siberie', name: 'Sibérie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    singapour: { type: 'singapour', name: 'Singapour', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    sriLanka: { type: 'sri-lanka', name: 'Sri Lanka', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tadjikistan: { type: 'tadjikistan', name: 'Tadjikistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    taiwan: { type: 'taiwan', name: 'Taiwan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    thailande: { type: 'thailande', name: 'Thailande', region: this.regionsTypes.asie,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tibet: { type: 'tibet', name: 'Tibet', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    timorLeste: { type: 'timor-leste', name: 'Timor Leste', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    turkmenistan: { type: 'turkmenistan', name: 'Turkmenistan', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    vietnam: { type: 'vietnam', name: 'Vietnam', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // CARAIBES
    anguilla: { type: 'anguilla', name: 'Anguilla', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    antigua: { type: 'antigua', name: 'Antigua', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    aruba: { type: 'aruba', name: 'Aruba', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bahamas: { type: 'bahamas', name: 'Bahamas', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    barbade: { type: 'barbade', name: 'Barbade', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bonaire: { type: 'bonaire', name: 'Bonaire', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    cuba: { type: 'cuba', name: 'Cuba', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    curacao: { type: 'curacao', name: 'Curacao', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    dominique: { type: 'dominique', name: 'Dominique', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    grenade: { type: 'grenade', name: 'Grenade', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guadeloupe: { type: 'guadeloupe', name: 'Guadeloupe', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    haiti: { type: 'haiti', name: 'Haïti', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesCaimans: { type: 'iles-caimans', name: 'Iles Caïmans', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesLeeward: { type: 'iles-leeward', name: 'Iles Leeward', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesTurquesEtCaiques: { type: 'iles-turques-et-caiques', name: 'Iles Turques et Caïques', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileVierge: { type: 'ile-vierge', name: 'Ile Vierge', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    jamaique: { type: 'jamaique', name: 'Jamaïque', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    martinique: { type: 'martinique', name: 'Martinique', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    montserrat: { type: 'montserrat', name: 'Montserrat', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    puertoRico: { type: 'puerto-rico', name: 'Puerto Rico', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    republiqueDominicaine: { type: 'republique-dominicaine', name: 'République Dominicaine', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    sainteLucie: { type: 'saint-lucie', name: 'Sainte Lucie', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    sanAndresEtProvidencia: { type: 'san-andres-et-providencia', name: 'San Andres et Providencia', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stBarth: { type: 'st-barth', name: 'St Barth', region: this.regionsTypes.amerique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stChristopheEtNieves: { type: 'st-christophe-et-nieves', name: 'St Christophe et Niévès', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stMartin: { type: 'st-martin', name: 'St Martin', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stVincentEtDeps: { type: 'st-vincent-et-deps', name: 'St Vincent et Deps', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    trinidadEtTobago: { type: 'trinidad-et-tobago', name: 'Trinidad et Tobago', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // EUROPE
    albanie: { type: 'albanie', name: 'Albanie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    allemagne: { type: 'allemagne', name: 'Allemagne', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    andorre: { type: 'andorre', name: 'Andorre', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    angleterre: { type: 'angleterre', name: 'Angleterre', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    autriche: { type: 'autriche', name: 'Autriche', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    belgique: { type: 'belgique', name: 'Belgique', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bielorussie: { type: 'bielorussie', name: 'Bielorussie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bosnieHerzegovine: { type: 'bosnie-herzegovine', name: 'Bosnie Herzegovine', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bulgarie: { type: 'bulgarie', name: 'Bulgarie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    chypre: { type: 'chypre', name: 'Chypre', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    crete: { type: 'crete', name: 'Crète', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    croatie: { type: 'croatie', name: 'Croatie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    danemark: { type: 'danemark', name: 'Danemark', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ecosse: { type: 'ecosse', name: 'Ecosse', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    espagne: { type: 'espagne', name: 'Espagne', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    estonie: { type: 'estonie', name: 'Estonie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    finlande: { type: 'finlande', name: 'Finlande', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    france: { type: 'france', name: 'France', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    gibraltar: { type: 'gibraltar', name: 'Gibraltar', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    grece: { type: 'grece', name: 'Grèce', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    hongrie: { type: 'hongrie', name: 'Hongrie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesAngloNormandes: { type: 'iles-anglo-normandes', name: 'Iles Anglo-Normandes', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileDuMan: { type: 'ile-du-man', name: 'Ile du Man', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    irlande: { type: 'irlande', name: 'Irlande', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    italie: { type: 'italie', name: 'Italie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    kosovo: { type: 'kosovo', name: 'Kosovo', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    liechtenstein: { type: 'liechtenstein', name: 'Liechtenstein', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    lettonie: { type: 'lettonie', name: 'Lettonie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    lituanie: { type: 'lituanie', name: 'Lituanie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    luxembourg: { type: 'luxembourg', name: 'Luxembourg', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    macedoine: { type: 'macedoine', name: 'Macedoine', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    malte: { type: 'malte', name: 'Malte', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    moldavie: { type: 'moldavie', name: 'Moldavie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    monaco: { type: 'monaco', name: 'Monaco', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    montenegro: { type: 'montenegro', name: 'Monténégro', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    norvege: { type: 'norvege', name: 'Norvège', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    paysBas: { type: 'pays-bas', name: 'Pays Bas', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    paysDeGalles: { type: 'pays-de-galles', name: 'Pays de Galles', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    pologne: { type: 'pologne', name: 'Pologne', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    portugal: { type: 'portugal', name: 'Portugal', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    republiqueTcheque: { type: 'republique-tcheque', name: 'République Tchèque', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    roumanie: { type: 'roumanie', name: 'Roumanie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    russie: { type: 'russie', name: 'Russie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    serbie: { type: 'serbie', name: 'Serbie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    slovaquie: { type: 'slovaquie', name: 'Slovaquie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    slovenie: { type: 'slovenie', name: 'Slovénie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    suede: { type: 'suede', name: 'Suède', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    suisse: { type: 'suisse', name: 'Suisse', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    turquie: { type: 'turquie', name: 'Turquie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ukraine: { type: 'ukraine', name: 'Ukraine', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // MOYEN-ORIENT
    abuDhabi: { type: 'abu-dhabi', name: 'Abu Dhabi', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ajman: { type: 'ajman', name: 'Ajman', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    arabieSaoudite: { type: 'arabie-saoudite', name: 'Arabie Saoudite', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    bahrein: { type: 'bahrein', name: 'Bahreïn', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    charjah: { type: 'charjah', name: 'Charjah', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    dubai: { type: 'dubai', name: 'Dubaï', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    fujairah: { type: 'fujairah', name: 'Fujairah', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    iran: { type: 'iran', name: 'Iran', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    irak: { type: 'irak', name: 'Irak', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    israel: { type: 'israel', name: 'Israël', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    jordanie: { type: 'jordanie', name: 'Jordanie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    koweit: { type: 'koweit', name: 'Koweït', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    liban: { type: 'liban', name: 'Liban', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    oman: { type: 'oman', name: 'Oman', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    oummAlQaiwain: { type: 'oumm-al-qaiwain', name: 'Oumm al Qaiwain', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    palestine: { type: 'palestine', name: 'Palestine', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    qatar: { type: 'qatar', name: 'Qatar', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    rasAlKhaimah: { type: 'ras-al-khaimah', name: 'Ras al Khaimah', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    syrie: { type: 'syrie', name: 'Syrie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    yemen: { type: 'yemen', name: 'Yemen', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
       // OCEAN ATLANTIQUE
    ascension: { type: 'ascension', name: 'Ascension', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    capVert: { type: 'cap-vert', name: 'Cap Vert', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    fernandoDoNoronha: { type: 'fernando-do-noronha', name: 'Fernando do Noronha', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    groenland: { type: 'groenland', name: 'Groenland', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesCanaries: { type: 'iles-canaries', name: 'Iles Canaries', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileDesBermudes: { type: 'ile-des-bermudes', name: 'Ile des Bermudes', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileFeroe: { type: 'ile-feroe', name: 'Ile Féroé', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesAcores: { type: 'iles-acores', name: 'Iles Açores', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesFalkland: { type: 'iles-falkland', name: 'Iles Falkland', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    islande: { type: 'islande', name: 'Islande', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    madeira: { type: 'madeira', name: 'Madeira', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    stHelena: { type: 'st-helena', name: 'St Helena', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tristanDaCunha: { type: 'tristan-da-cunha', name: 'Tristan da Cunha', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // OCEAN INDIEN
    andamanIlesNicobar: { type: 'andaman-iles-nicobar', name: 'Andaman iles Nicobar', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileCocos: { type: 'ile-cocos', name: 'Ile Cocos', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileDeLaReunion: { type: 'ile-de-la-reunion', name: 'Ile de la Reunion', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileMaurice: { type: 'ile-maurice', name: 'Ile Maurice', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesChristmas: { type: 'iles-christmas', name: 'Iles Christmas', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesComores: { type: 'iles-comores', name: 'Iles Comores', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesRodriguez: { type: 'iles-rodriguez', name: 'Iles Rodriguez', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    lakshadweep: { type: 'lakshadweep', name: 'Lakshadweep', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    madagascar: { type: 'madagascar', name: 'Madagascar', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    maldives: { type: 'maldives', name: 'Maldives', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    mayotte: { type: 'mayotte', name: 'Mayotte', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    seychelles: { type: 'seychelles', name: 'Seychelles', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    // OCEAN PACIFIQUE
    archipelJuanFernandez: { type: 'archipel-juan-fernandez', name: 'Archipel Juan Fernandez', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    australie: { type: 'australie', name: 'Australie', region: this.regionsTypes.oceanPacifique,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    fidji: { type: 'fidji', name: 'Fidji', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    guam: { type: 'guam', name: 'Guam', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    hawai: { type: 'hawai', name: 'Hawaï', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileNorfolk: { type: 'ile-norfolk', name: 'Ile Norfolk', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesCook: { type: 'iles-cook', name: 'Iles Cook', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesDePaques: { type: 'iles-de-paques', name: 'Iles de Paques', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesGalapagos: { type: 'iles-galapagos', name: 'Iles Galapagos', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesKiribati: { type: 'iles-kiribati', name: 'Iles Kiribati', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesMariannes: { type: 'iles-mariannes', name: 'Iles Mariannes', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesMarquises: { type: 'iles-marquises', name: 'Iles Marquises', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesMarshall: { type: 'iles-marshall', name: 'Iles Marshall', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesMidway: { type: 'iles-midway', name: 'Iles Midway', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesPitcairn: { type: 'iles-pitcairn', name: 'Iles Pitcairn', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesSalomon: { type: 'iles-salomon', name: 'Iles Salomon', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ilesSamoa: { type: 'iles-samoa', name: 'Iles Samoa', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ileWake: { type: 'ile-wake', name: 'Ile Wake', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    lordHoweIsland: { type: 'lord-howe-island', name: 'Lord Howe Island', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    micronesie: { type: 'micronesie', name: 'Micronésie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nauru: { type: 'nauru', name: 'Nauru', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    niue: { type: 'niue', name: 'niue', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nouvelleCaledonie: { type: 'nouvelle-caledonie', name: 'Nouvelle Caledonie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    nouvelleZelande: { type: 'nouvelle-zelande', name: 'Nouvelle-Zelande', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ogasawara: { type: 'ogasawara', name: 'Ogasawara', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    palaos: { type: 'palaos', name: 'Palaos', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    polynesieFrancaise: { type: 'polynesie-francaise', name: 'Polynésie Française', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    ryukyu: { type: 'ryukyu', name: 'Ryukyu', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tasmanie: { type: 'tasmanie', name: 'Tasmanie', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tokelau: { type: 'tokelau', name: 'Tokelau', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tonga: { type: 'tonga', name: 'Tonga', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    tuvalu: { type: 'tuvalu', name: 'Tuvalu', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    vanuatu: { type: 'vanuatu', name: 'Vanuatu', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
    wallisEtFutuna: { type: 'wallis-et-futuna', name: 'Wallis et Futuna', region: this.regionsTypes.europe,
      pictures: [{title: 'best #1', source: 'assets/images/angola/best1.jpg'}, {title: 'best #2', source: 'assets/images/angola/best2.jpg' }, {title: 'best #3', source: 'assets/images/angola/best3.jpg' }, {title: 'best #4', source: 'assets/images/angola/best4.jpg'}],
    },
  };

  private allCountries: AllCountryPictures[] = [
    { country: this.countries.afriqueDuSud },
    { country: this.countries.algerie },
    { country: this.countries.angola },
    { country: this.countries.benin },
    { country: this.countries.botswana },
    { country: this.countries.burkinaFaso },
    { country: this.countries.burundi },
    { country: this.countries.cabinda },
    { country: this.countries.cameroun },
    { country: this.countries.tchad },
    { country: this.countries.coteDIvoire },
    { country: this.countries.djibouti },
    { country: this.countries.egypte },
    { country: this.countries.erythree },
    { country: this.countries.ethiopie },
    { country: this.countries.gabon },
    { country: this.countries.gambie },
    { country: this.countries.ghana },
    { country: this.countries.guineeBissau },
    { country: this.countries.guineeEquatoriale },
    { country: this.countries.guineeFrancaise },
    { country: this.countries.kenya },
    { country: this.countries.lesotho },
    { country: this.countries.liberie },
    { country: this.countries.libye },
    { country: this.countries.malawi },
    { country: this.countries.mali },
    { country: this.countries.maroc },
    { country: this.countries.mauritanie },
    { country: this.countries.mozambique },
    { country: this.countries.namibie },
    { country: this.countries.niger },
    { country: this.countries.nigeria },
    { country: this.countries.ouganda },
    { country: this.countries.republiqueCentrafricaine },
    { country: this.countries.republiqueDemocratiqueDuCongo },
    { country: this.countries.republiqueDuCongo },
    { country: this.countries.rwanda },
    { country: this.countries.saharaOccidental },
    { country: this.countries.saoTomeEtPrincipe },
    { country: this.countries.senegal },
    { country: this.countries.sierraLeone },
    { country: this.countries.somalie },
    { country: this.countries.somaliland },
    { country: this.countries.soudan },
    { country: this.countries.soudanDuSud },
    { country: this.countries.swaziland },
    { country: this.countries.tanzanie },
    { country: this.countries.togo },
    { country: this.countries.tunisie },
    { country: this.countries.zambie },
    { country: this.countries.zimbabwe },
    { country: this.countries.alaska },
    { country: this.countries.canada },
    { country: this.countries.etatsUnis },
    { country: this.countries.ilePrinceEdward },
    { country: this.countries.mexique },
    { country: this.countries.stPierreEtMiquelon },
    { country: this.countries.argentine },
    { country: this.countries.bolivie },
    { country: this.countries.bresil },
    { country: this.countries.chili },
    { country: this.countries.colombie },
    { country: this.countries.equateur },
    { country: this.countries.guyane },
    { country: this.countries.guyaneFrancaise },
    { country: this.countries.paraguay },
    { country: this.countries.peru },
    { country: this.countries.suriname },
    { country: this.countries.uruguay },
    { country: this.countries.venezuela },
    { country: this.countries.belize },
    { country: this.countries.costaRica },
    { country: this.countries.elSalvador },
    { country: this.countries.guatemala },
    { country: this.countries.honduras },
    { country: this.countries.nicaragua },
    { country: this.countries.panama },
    { country: this.countries.abkhazia },
    { country: this.countries.afghanistan },
    { country: this.countries.armenie },
    { country: this.countries.azerbaidjan },
    { country: this.countries.bangladesh },
    { country: this.countries.bhoutan },
    { country: this.countries.brunei },
    { country: this.countries.cambodge },
    { country: this.countries.chine },
    { country: this.countries.coreeDuNord },
    { country: this.countries.coreeDuSud },
    { country: this.countries.georgie },
    { country: this.countries.inde },
    { country: this.countries.indonesie },
    { country: this.countries.japon },
    { country: this.countries.kazakhstan },
    { country: this.countries.kirghizistan },
    { country: this.countries.laos },
    { country: this.countries.malaisie },
    { country: this.countries.mongolie },
    { country: this.countries.myanmar },
    { country: this.countries.nakhchivan },
    { country: this.countries.nepal },
    { country: this.countries.pakistan },
    { country: this.countries.papouasie },
    { country: this.countries.philippines },
    { country: this.countries.siberie },
    { country: this.countries.singapour },
    { country: this.countries.sriLanka },
    { country: this.countries.tadjikistan },
    { country: this.countries.taiwan },
    { country: this.countries.thailande },
    { country: this.countries.tibet },
    { country: this.countries.timorLeste },
    { country: this.countries.turkmenistan },
    { country: this.countries.ouzbekistan },
    { country: this.countries.vietnam },
    { country: this.countries.anguilla },
    { country: this.countries.antigua },
    { country: this.countries.aruba },
    { country: this.countries.bahamas },
    { country: this.countries.barbade },
    { country: this.countries.bonaire },
    { country: this.countries.cuba },
    { country: this.countries.curacao },
    { country: this.countries.dominique },
    { country: this.countries.grenade },
    { country: this.countries.guadeloupe },
    { country: this.countries.haiti },
    { country: this.countries.ilesCaimans },
    { country: this.countries.ilesLeeward },
    { country: this.countries.ilesTurquesEtCaiques },
    { country: this.countries.ileVierge },
    { country: this.countries.jamaique },
    { country: this.countries.martinique },
    { country: this.countries.montserrat },
    { country: this.countries.puertoRico },
    { country: this.countries.republiqueDominicaine },
    { country: this.countries.sanAndresEtProvidencia },
    { country: this.countries.dominique },
    { country: this.countries.stBarth },
    { country: this.countries.stChristopheEtNieves },
    { country: this.countries.stMartin },
    { country: this.countries.stVincentEtDeps },
    { country: this.countries.trinidadEtTobago },
    { country: this.countries.albanie },
    { country: this.countries.allemagne },
    { country: this.countries.andorre },
    { country: this.countries.angleterre },
    { country: this.countries.autriche },
    { country: this.countries.belgique },
    { country: this.countries.bielorussie },
    { country: this.countries.bosnieHerzegovine },
    { country: this.countries.bulgarie },
    { country: this.countries.chypre },
    { country: this.countries.crete },
    { country: this.countries.croatie },
    { country: this.countries.danemark },
    { country: this.countries.ecosse },
    { country: this.countries.espagne },
    { country: this.countries.estonie },
    { country: this.countries.finlande },
    { country: this.countries.france },
    { country: this.countries.gibraltar },
    { country: this.countries.grece },
    { country: this.countries.hongrie },
    { country: this.countries.ilesAngloNormandes },
    { country: this.countries.ileDuMan },
    { country: this.countries.irlande },
    { country: this.countries.italie },
    { country: this.countries.kosovo },
    { country: this.countries.liechtenstein },
    { country: this.countries.lettonie },
    { country: this.countries.lituanie },
    { country: this.countries.luxembourg },
    { country: this.countries.macedoine },
    { country: this.countries.malte },
    { country: this.countries.moldavie },
    { country: this.countries.monaco },
    { country: this.countries.montenegro },
    { country: this.countries.norvege },
    { country: this.countries.paysBas },
    { country: this.countries.paysDeGalles },
    { country: this.countries.pologne },
    { country: this.countries.portugal },
    { country: this.countries.republiqueTcheque },
    { country: this.countries.roumanie },
    { country: this.countries.russie },
    { country: this.countries.serbie },
    { country: this.countries.slovaquie },
    { country: this.countries.slovenie },
    { country: this.countries.suede },
    { country: this.countries.suisse },
    { country: this.countries.turquie },
    { country: this.countries.ukraine },
    { country: this.countries.abuDhabi },
    { country: this.countries.ajman },
    { country: this.countries.arabieSaoudite },
    { country: this.countries.bahrein },
    { country: this.countries.charjah },
    { country: this.countries.dubai },
    { country: this.countries.fujairah },
    { country: this.countries.iran },
    { country: this.countries.irak },
    { country: this.countries.israel },
    { country: this.countries.jordanie },
    { country: this.countries.koweit },
    { country: this.countries.liban },
    { country: this.countries.oman },
    { country: this.countries.oummAlQaiwain },
    { country: this.countries.palestine },
    { country: this.countries.qatar },
    { country: this.countries.rasAlKhaimah },
    { country: this.countries.syrie },
    { country: this.countries.yemen },
    { country: this.countries.ascension },
    { country: this.countries.capVert },
    { country: this.countries.fernandoDoNoronha },
    { country: this.countries.groenland },
    { country: this.countries.ileDesBermudes },
    { country: this.countries.ileFeroe },
    { country: this.countries.ilesAcores },
    { country: this.countries.ilesCanaries },
    { country: this.countries.ilesFalkland },
    { country: this.countries.islande },
    { country: this.countries.madeira },
    { country: this.countries.stHelena },
    { country: this.countries.tristanDaCunha },
    { country: this.countries.andamanIlesNicobar },
    { country: this.countries.ileCocos },
    { country: this.countries.ileDeLaReunion },
    { country: this.countries.ileMaurice },
    { country: this.countries.ilesChristmas },
    { country: this.countries.ilesComores },
    { country: this.countries.ilesRodriguez },
    { country: this.countries.lakshadweep },
    { country: this.countries.madagascar },
    { country: this.countries.maldives },
    { country: this.countries.mayotte },
    { country: this.countries.seychelles },
    { country: this.countries.archipelJuanFernandez },
    { country: this.countries.australie },
    { country: this.countries.fidji },
    { country: this.countries.guam },
    { country: this.countries.hawai },
    { country: this.countries.ileNorfolk },
    { country: this.countries.ilesCook },
    { country: this.countries.ilesDePaques },
    { country: this.countries.ilesGalapagos },
    { country: this.countries.ilesKiribati },
    { country: this.countries.ilesMariannes },
    { country: this.countries.ilesMarquises },
    { country: this.countries.ilesMarshall },
    { country: this.countries.ilesMidway },
    { country: this.countries.ilesPitcairn },
    { country: this.countries.ilesSalomon },
    { country: this.countries.ilesSamoa },
    { country: this.countries.ileWake },
    { country: this.countries.lordHoweIsland },
    { country: this.countries.micronesie },
    { country: this.countries.nauru },
    { country: this.countries.niue },
    { country: this.countries.nouvelleCaledonie },
    { country: this.countries.nouvelleZelande },
    { country: this.countries.ogasawara },
    { country: this.countries.palaos },
    { country: this.countries.polynesieFrancaise },
    { country: this.countries.ryukyu },
    { country: this.countries.tasmanie },
    { country: this.countries.tokelau },
    { country: this.countries.tonga },
    { country: this.countries.tuvalu },
    { country: this.countries.vanuatu },
    { country: this.countries.wallisEtFutuna },
  ];
  
  getCamerasData(): Observable<Picture[]> {
    return observableOf(this.cameras);
  }

  onClickUpdate(countryName: string){
    this.countryName = countryName;
    this.countryNameChanges.next();
  }

  onClickUpdatePictures(countryName: string){
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.name === countryName) this.cameras = this.allCountries[i].country.pictures;
    }
    this.camerasChanges.next();
  }

  getCountryPictures(countryName: string): Picture[] {
    for (let i = 0; i < this.allCountries.length; i++) {
      if (this.allCountries[i].country.name === countryName) return this.allCountries[i].country.pictures;
    }
  }
}
