import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CountryInformationData, CountryInformationSummary } from '../data/country-information';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryInformationService extends CountryInformationData {

  constructor(private http: HttpClient) {
    super();
  }
  
  getInformationJson(lienInfo: string): Observable<any> {
      return this.http.get(lienInfo);  
  }

}
