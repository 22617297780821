import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, UserData } from '../data/users';

@Injectable()
export class UserService extends UserData {

  private time: Date = new Date;

  private users = {
    beatsfaty: { name: 'Beatsfaty', picture: 'assets/images/nick.png' },
    vicky: { name: 'Vicky', picture: 'assets/images/eva.png' },
 
  };
  

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

}
