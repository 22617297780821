import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { CountryOrderData } from '../data/country-order';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryOrderService extends CountryOrderData {

  constructor(private http: HttpClient) {
    super();
  }
  private countriesCategories = [
    'Mer',
    'Montagne',
    'Lac / Rivières',
    'Villes',
    'Desert',
    'Vue',
  ];


  getCountriesCategories(): Observable<string[]> {
    return observableOf(this.countriesCategories);
  }
}
